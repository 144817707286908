import { Avatar, Tooltip } from '@mui/material';
import Color from 'color';

function CustomStringAvatar({ text, bgColor, small, avatarSize, icon }) {
    const smallSizeProps = small
        ? { width: avatarSize, height: avatarSize, fontSize: '12px !important' }
        : { fontSize: '15px !important' };

    const stringParts = text
        ?.toUpperCase()
        .split(' ')
        .filter(part => part.length);

    return {
        sx: {
            bgcolor: bgColor,
            fontWeight: 500,
            p: 0.5,
            boxSizing: 'border-box',
            color: Color(bgColor).isDark() ? '#fff' : '#000',
            ...smallSizeProps
        },
        children: icon
            ? icon
            : text?.length > 0
              ? stringParts.length > 1
                  ? `${stringParts[0][0]}${stringParts[stringParts.length - 1][0]}`
                  : stringParts[0][0]
              : ''
    };
}

export default function StringAvatar({
    text,
    bgColor,
    showTooltip = false,
    small = false,
    avatarSize = 26,
    icon = null
}) {
    const _avatar = <Avatar {...CustomStringAvatar({ text, bgColor, small, avatarSize, icon })} />;
    return showTooltip ? (
        <Tooltip title={text} describeChild>
            {_avatar}
        </Tooltip>
    ) : (
        _avatar
    );
}
