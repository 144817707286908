import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'Este campo';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} é inválido`,
        required: ({ path }) => `${getFieldName(path)} é um campo obrigatório`,
        defined: ({ path }) => `${getFieldName(path)} deve ser definido`,
        notNull: ({ path }) => `${getFieldName(path)} não pode ser nulo`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} deve ser um dos seguintes valores: ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} não deve ser um dos seguintes valores: ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} deve ser do tipo \`${i18next.t(type)}\`, ` +
                `mas o valor final foi: \`${printValue(value, true)}\`` +
                (isCast ? ` (convertido de \`${printValue(originalValue, true)}\`).` : '.');

            if (value === null) {
                msg +=
                    `\n Se "nulo" é intencionado como um valor vazio, certifique-se de marcar o esquema como` +
                    ' `.nullable()`';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) =>
            `${getFieldName(path)} deve ter exatamente ${length} caracteres`,
        min: ({ path, min }) => `${getFieldName(path)} deve ter pelo menos ${min} caracteres`,
        max: ({ path, max }) => `${getFieldName(path)} deve ter no máximo ${max} caracteres`,
        matches: ({ path, regex }) =>
            `${getFieldName(path)} deve corresponder ao seguinte: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} deve ser um email válido`,
        url: ({ path }) => `${getFieldName(path)} deve ser um URL válido`,
        uuid: ({ path }) => `${getFieldName(path)} deve ser um UUID válido`,
        trim: ({ path }) =>
            `${getFieldName(path)} deve ser uma string sem espaços no início ou no fim`,
        lowercase: ({ path }) => `${getFieldName(path)} deve ser em letras minúsculas`,
        uppercase: ({ path }) => `${getFieldName(path)} deve ser em letras maiúsculas`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} deve ser maior ou igual a ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} deve ser menor ou igual a ${max}`,
        lessThan: ({ path, less }) => `${getFieldName(path)} deve ser menor que ${less}`,
        moreThan: ({ path, more }) => `${getFieldName(path)} deve ser maior que ${more}`,
        positive: ({ path }) => `${getFieldName(path)} deve ser um número positivo`,
        negative: ({ path }) => `${getFieldName(path)} deve ser um número negativo`,
        integer: ({ path }) => `${getFieldName(path)} deve ser um inteiro`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} campo deve ser após ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} campo deve ser antes de ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} campo tem chaves não especificadas: ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)} campo deve ter pelo menos ${min} itens`,
        max: ({ path, max }) => `${getFieldName(path)} campo deve ter no máximo ${max} itens`,
        length: ({ path, length }) => `${getFieldName(path)} deve ter ${length} itens`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} campo deve ser ${i18next.t(value)}`
    }
};
