import { Box, Select, MenuItem, Avatar } from '@mui/material';

export function IssueType({ issueType, handleChange, jiraIssueTypes }) {
    const handleIssueTypeChange = e => {
        handleChange('issuetype', e.target.value);
    };

    return (
        <Select
            labelId='issue-type-selectlist'
            label='Issue Type'
            onChange={handleIssueTypeChange}
            value={issueType}
            variant='standard'
            sx={{ minWidth: { xs: '100%', sm: '150px' } }}
        >
            {jiraIssueTypes
                ?.filter(type => type.hierarchyLevel === 0)
                .map(type => (
                    <MenuItem key={type.id} value={type.id}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                overflow: 'hidden'
                            }}
                        >
                            <Avatar
                                alt={`JIRA-ISSUE-TYPE-${type.name}`}
                                sx={{ width: 32, height: 32 }}
                                src={type?.iconUrl}
                            />{' '}
                            {type?.name}
                        </Box>
                    </MenuItem>
                ))}
        </Select>
    );
}
