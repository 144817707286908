import { currentAuditEntryAtom, reportDemoModeAtom, userAtom } from '@/global-store';
import userHasAccessToEntry from '@/helpers/userHasAccessToEntry';
import DynamicDialog from '@components/DynamicDialog';
import LevelIcon from '@components/LevelIcon';
import { useFetchAccessibilityData, useUpdateConformanceTarget } from '@http/queries';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularRatingWithInnerLabel from '@reportComponents/CircularRatingWithInnerLabel.jsx';
import NoSaveInDemoBanner from '@reportComponents/NoSaveInDemoBanner';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function setSelectionsFromConformanceTarget(
    conformanceTarget,
    setSelectedTargetLevel,
    setBestPractiseChecked
) {
    if (!conformanceTarget) return;
    const split = conformanceTarget.split(' + ');

    setSelectedTargetLevel(split[0]);
    setBestPractiseChecked(!!split[1]);
}

function ConformanceTargetSelect({ small = false }) {
    const [selectedTargetLevel, setSelectedTargetLevel] = useState();
    const [bestPractiseChecked, setBestPractiseChecked] = useState();

    const { AccessibilityData } = useFetchAccessibilityData();

    const [reportDemoMode] = useAtom(reportDemoModeAtom);
    const { t } = useTranslation();
    const [user] = useAtom(userAtom);
    const [entry] = useAtom(currentAuditEntryAtom);
    const conformanceTarget = AccessibilityData?.conformanceTarget;

    const mutation = useUpdateConformanceTarget();

    useEffect(() => {
        setSelectionsFromConformanceTarget(
            conformanceTarget,
            setSelectedTargetLevel,
            setBestPractiseChecked
        );

        return () => {
            setSelectedTargetLevel(null);
            setBestPractiseChecked(null);
        };
    }, [conformanceTarget]);

    const handleLevelChange = event => {
        setSelectedTargetLevel(event.target.value);
    };

    function bestPracticeValueChanged(event) {
        setBestPractiseChecked(event.target.checked);
    }

    const handleTargetSave = async () => {
        const newTarget = selectedTargetLevel + (bestPractiseChecked ? ' + Best Practice' : '');

        await mutation.mutateAsync(newTarget);

        //return window.location.reload();

        return true; // this is needed for the dialog to close
    };

    function handleDialogCancel() {
        setSelectionsFromConformanceTarget(
            conformanceTarget,
            setSelectedTargetLevel,
            setBestPractiseChecked
        );
        return true; // this is needed for the dialog to close
    }

    const rating = AccessibilityData.calcOverallAuditScore(
        AccessibilityData.auditScores,
        false,
        selectedTargetLevel + (bestPractiseChecked ? ' + Best Practice' : '')
    );

    return (
        <DynamicDialog
            activatorProps={{
                'size': small ? 'small' : 'medium',
                'sx': {
                    backgroundColor: 'rgba(255,255,255,0.33)',
                    border: '1.5px solid rgba(18, 18, 18, 0.5) !important',
                    width: { xs: '100%', sm: 'auto' },
                    height: '40px',
                    py: '7.5px',
                    px: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '3px',
                    flexWrap: 'wrap'
                },
                'aria-label': t('accessibilityButtonText1') + ': ' + conformanceTarget
            }}
            activatorText={
                <>
                    {/* {small ? null : t('accessibilityButtonText1') + ': '} */}
                    {/* <span style={{ width: '7px' }} /> */}
                    <span style={{ fontSize: '1.1rem', paddingTop: '1px' }}>{t('target')}: </span>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '3px',
                            ml: 0.5
                        }}
                    >
                        {conformanceTarget.includes('AAA') ? (
                            <LevelIcon level='AAA' />
                        ) : conformanceTarget.includes('AA') ? (
                            <LevelIcon level='AA' />
                        ) : (
                            <LevelIcon level='A' />
                        )}

                        {conformanceTarget.includes('Best Practice') && (
                            <LevelIcon level='Best Practice' />
                        )}
                    </Box>
                </>
            }
            disableSave={reportDemoMode || !userHasAccessToEntry(user, entry)}
            onCancel={handleDialogCancel}
            onSubmit={handleTargetSave}
            title={t('conformanceTargetHeading')}
            toolTipText={t('conformanceDialogButtonTooltip')}
        >
            <Grid
                container
                alignItems='center'
                direction={{ xs: 'column', md: 'row' }}
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                justifyContent='center'
                p={2}
                spacing={3}
            >
                <Grid>
                    <Box>{t('conformanceTargetSubhead')}</Box>

                    <FormControl>
                        <RadioGroup
                            aria-labelledby='demo-radio-buttons-group-label'
                            name='radio-buttons-group'
                            onChange={handleLevelChange}
                            sx={{ my: 1 }}
                            value={selectedTargetLevel}
                        >
                            <FormControlLabel
                                control={<Radio />}
                                label={<LevelIcon level='A' showName />}
                                value='A'
                            />

                            <FormControlLabel
                                control={<Radio />}
                                label={
                                    <Stack direction='row' spacing={1}>
                                        <LevelIcon level='A' />

                                        <LevelIcon level='AA' showName />
                                    </Stack>
                                }
                                value='AA'
                            />

                            <FormControlLabel
                                control={<Radio />}
                                label={
                                    <Stack direction='row' spacing={1}>
                                        <LevelIcon level='A' />

                                        <LevelIcon level='AA' />

                                        <LevelIcon level='AAA' showName />
                                    </Stack>
                                }
                                value='AAA'
                            />
                        </RadioGroup>
                    </FormControl>

                    <Box mt={3}>{t('conformanceTargetText')}</Box>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={bestPractiseChecked}
                                inputProps={{
                                    'aria-label': 'controlled'
                                }}
                                onChange={bestPracticeValueChanged}
                            />
                        }
                        label={<LevelIcon level='Best Practice' showName />}
                    />
                </Grid>

                <Grid>
                    <CircularRatingWithInnerLabel rating={rating || 0} />
                </Grid>
            </Grid>

            <NoSaveInDemoBanner
                sx={{
                    mt: 2,
                    mb: 1.5
                }}
            />
        </DynamicDialog>
    );
}

export default ConformanceTargetSelect;
