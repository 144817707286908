import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'This field';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} is invalid`,
        required: ({ path }) => `${getFieldName(path)} is a required field`,
        defined: ({ path }) => `${getFieldName(path)} must be defined`,
        notNull: ({ path }) => `${getFieldName(path)} cannot be null`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} must be one of the following values: ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} must not be one of the following values: ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} must be a \`${i18next.t(type)}\` type, ` +
                `but the final value was: \`${printValue(value, true)}\`` +
                (isCast ? ` (cast from the value \`${printValue(originalValue, true)}\`).` : '.');

            if (value === null) {
                msg +=
                    `\n If "null" is intended as an empty value be sure to mark the schema as` +
                    ' `.nullable()`';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) => `${getFieldName(path)} must be exactly ${length} characters`,
        min: ({ path, min }) => `${getFieldName(path)} must be at least ${min} characters`,
        max: ({ path, max }) => `${getFieldName(path)} must be at most ${max} characters`,
        matches: ({ path, regex }) => `${getFieldName(path)} must match the following: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} must be a valid email`,
        url: ({ path }) => `${getFieldName(path)} must be a valid URL`,
        uuid: ({ path }) => `${getFieldName(path)} must be a valid UUID`,
        trim: ({ path }) => `${getFieldName(path)} must be a trimmed string`,
        lowercase: ({ path }) => `${getFieldName(path)} must be a lowercase string`,
        uppercase: ({ path }) => `${getFieldName(path)} must be a upper case string`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} must be greater than or equal to ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} must be less than or equal to ${max}`,
        lessThan: ({ path, less }) => `${getFieldName(path)} must be less than ${less}`,
        moreThan: ({ path, more }) => `${getFieldName(path)} must be greater than ${more}`,
        positive: ({ path }) => `${getFieldName(path)} must be a positive number`,
        negative: ({ path }) => `${getFieldName(path)} must be a negative number`,
        integer: ({ path }) => `${getFieldName(path)} must be an integer`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} field must be later than ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} field must be at earlier than ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} field has unspecified keys: ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)} field must have at least ${min} items`,
        max: ({ path, max }) =>
            `${getFieldName(path)} field must have less than or equal to ${max} items`,
        length: ({ path, length }) => `${getFieldName(path)} must have ${length} items`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} field must be ${i18next.t(value)}`
    }
};
