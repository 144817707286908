import BoxWithTitle from '@/components/BoxWithTitle';
import ButtonSwitch from '@components/ButtonSwitch';
import CustomTextField from '@components/CustomTextField';
import InfoBox from '@components/InfoBox';
import InputHelperText from '@components/InputHelperText';
import { parseInputString } from '@helpers/functions';
import { Box, Button, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import {
    IconArrowBarToLeft,
    IconArrowBarToRight,
    IconDeviceDesktop,
    IconDeviceMobile,
    IconHandClick
} from '@tabler/icons-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function PositionForm({ dataValues, handleInputChange }) {
    const timeoutRef = useRef();
    const { t } = useTranslation();

    const debounce = (func, delay) => {
        return function (...args) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = null;
                func.apply(this, args);
            }, delay);
        };
    };

    const delayedFunction = debounce((name, value, validationFunc) => {
        let hasError = false;
        if (validationFunc) {
            hasError = !validationFunc(value);
            handleInputChange(name, value, hasError, true);
        }
    }, 500);

    function handleValueChanged(name, value, validationFunc = undefined) {
        handleInputChange(name, value);

        delayedFunction(name, value, validationFunc);
    }

    return (
        <>
            <Positions dataValues={dataValues} handleValueChanged={handleValueChanged} />

            <Grid sx={{ width: '100%' }}>
                <InfoBox text={t('customizeAppearanceText')}>
                    <Button
                        href='https://eye-able.com/einbettung-von-eye-able-direkt-in-die-seite/'
                        startIcon={<IconHandClick />}
                        variant='outlined'
                    >
                        {t('embeddingEyeableInPageBtnText')}
                    </Button>
                </InfoBox>
            </Grid>
        </>
    );
}

function Positions({ dataValues, handleValueChanged }) {
    const { t } = useTranslation();

    const positionFields = {
        desktop: {
            icon: IconDeviceDesktop,
            title: t('astCustomGDesktopPos'),
            inputFields: [
                {
                    key: 'topPosition',
                    title: 'titleTopPosition',
                    description: 'desTopPosition',
                    errorMsg: 'errorTopPosition',
                    defaultValue: '15%'
                },
                {
                    key: 'rightPosition',
                    title: 'titleRightPosition',
                    description: 'descRightPosition',
                    errorMsg: 'errorRightPosition',
                    defaultValue: '10px'
                }
            ],
            sidePosition: 'sidePosition'
        },
        mobile: {
            icon: IconDeviceMobile,
            title: t('astCustomGMobilePos'),
            inputFields: [
                {
                    key: 'mobileBottomPosition',
                    title: 'titleMobileBottomPosition',
                    description: 'descMobileBottomPosition',
                    errorMsg: 'errorMobileBottomPosition',
                    defaultValue: '15%'
                },
                {
                    key: 'mobileRightPosition',
                    title: 'titleMobileRightPosition',
                    description: 'desMobileRightPosition',
                    errorMsg: 'errorMobileBottomPosition',
                    defaultValue: '10px'
                }
            ],
            sidePosition: 'mobileSidePosition'
        }
    };

    function validatePosition(value) {
        const [unit, numb] = parseInputString(value);
        return /^\d+$/.test(numb) && (unit === 'px' || unit === '%');
    }

    return Object.keys(positionFields).map(key => {
        return (
            <Grid key={key} lg={6} xs={12}>
                <BoxWithTitle icon={positionFields[key].icon} title={positionFields[key].title}>
                    <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
                        {positionFields[key].inputFields.map(field => (
                            <Box key={field.key}>
                                <CustomTextField
                                    description={t(field.description)}
                                    error={dataValues[field.key].error}
                                    errorMsg={t(field.errorMsg)}
                                    fieldKey={field.key}
                                    handleChange={e => {
                                        handleValueChanged(
                                            field.key,
                                            e.target.value,
                                            validatePosition
                                        );
                                    }}
                                    label={t(field.title)}
                                    value={dataValues[field.key].value}
                                />
                            </Box>
                        ))}

                        <SidePosition
                            dataValues={dataValues}
                            fieldKey={positionFields[key].sidePosition}
                            handleValueChanged={handleValueChanged}
                        />
                    </Box>
                </BoxWithTitle>
            </Grid>
        );
    });
}

function SidePosition({ dataValues, handleValueChanged, fieldKey }) {
    const { t } = useTranslation();

    return (
        <Box>
            <InputLabel htmlFor={fieldKey} sx={{ fontSize: '1rem', fontWeight: '500', mb: 1 }}>
                {t('titleSidePosition')}
            </InputLabel>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ButtonSwitch
                    handleChange={e => handleValueChanged(fieldKey, e)}
                    optionAProps={{ startIcon: <IconArrowBarToLeft /> }}
                    optionATitle={t('leftEditorSwitch')}
                    optionAVal='left'
                    optionBProps={{ endIcon: <IconArrowBarToRight /> }}
                    optionBTitle={t('rightEditorSwitch')}
                    optionBVal='right'
                    value={dataValues[fieldKey].value}
                />
            </Box>

            <InputHelperText text={t('assistCustomSidePosDesc') + 'BLA'} />
        </Box>
    );
}
