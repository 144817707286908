import RouterLink from '@/components/RouterLink';
import { forwardRef } from 'react';

const LinkBehavior = forwardRef(function LinkBehaviorRef(props, ref) {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

export default LinkBehavior;
