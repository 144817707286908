import SupportPopupButton from '@components/SupportPopupButton.jsx';
import { Box, Card } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function SupportPopupWithContainer({ containerText }) {
    return (
        <Grid alignItems='center' container justifyContent='center'>
            <Card sx={{ p: 2, borderRadius: '28px' }}>
                <Grid alignItems='center' container gap={3.5} justifyContent='space-around' p={1}>
                    <img
                        alt='help icon'
                        src='https://eye-able-storage.b-cdn.net/dashboard-assets/icons/accessibility_black_circled.webp'
                        style={{ width: '4rem' }}
                    />

                    <Box textAlign='center'>{containerText}</Box>

                    <SupportPopupButton color='primary' />
                </Grid>
            </Card>
        </Grid>
    );
}
