import { Avatar, Box, Tooltip } from '@mui/material';
import { IconThumbUp } from '@tabler/icons-react';
import { forwardRef } from 'react';

const colors = {
    'Best Practice': '#8510c7',
    'A': '#a30000',
    'AA': '#002060',
    'AAA': '#15651b'
};

const LevelIcon = forwardRef(function LevelIconRef({ level, showName, sx, ...rest }, ref) {
    return (
        <Box {...rest} alignItems='center' display='flex' justifyContent='center' ref={ref} sx={sx}>
            <Tooltip placement='top' title={level === 'Best Practice' ? level : 'Level ' + level}>
                <Avatar
                    aria-label={level}
                    sx={{
                        background: colors[level],
                        height: '24px',
                        width: '44px',
                        fontSize: '12px',
                        borderRadius: '5px',
                        color: 'white !important',
                        fontWeight: 400
                    }}
                    variant='square'
                >
                    {level === 'Best Practice' ? <IconThumbUp color='white' size='17px' /> : level}
                </Avatar>
            </Tooltip>

            {showName ? (
                <Box aria-hidden fontWeight={500} ml={1.25}>
                    {level === 'Best Practice' ? '' : 'Level '}

                    {level}
                </Box>
            ) : null}
        </Box>
    );
});

export default LevelIcon;
