import { Box, InputLabel, TextField } from '@mui/material';

export default function CustomTextField({
    label = null,
    value,
    fieldKey,
    error = false,
    errorMsg = '',
    description = null,
    handleChange = null,
    placeholder = null,
    inputProps = {},
    controlledInput = true
}) {
    let customProps = { ...inputProps };
    if (controlledInput) {
        customProps = { ...customProps, onChange: handleChange, value };
    } else {
        customProps = { ...customProps, defaultValue: value };
    }
    return (
        <Box>
            {!!label && (
                <InputLabel
                    htmlFor={fieldKey}
                    sx={{
                        fontSize: '1rem',
                        mt: 1,
                        mb: 1,
                        fontWeight: 500,
                        color: error ? 'error.main' : 'text.primary'
                    }}
                >
                    {label}
                </InputLabel>
            )}

            <TextField
                aria-describedby={`input field for ${label}`}
                error={error}
                fullWidth
                id={fieldKey}
                name={fieldKey}
                placeholder={placeholder}
                variant='outlined'
                helperText={error ? errorMsg : description}
                {...customProps}
            />
        </Box>
    );
}
