import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function CircularProgressWithLabel({ value, color = '#000', valueWidth = '54px', ...rest }) {
    const val = Number.parseFloat(value);

    return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1.5 }}>
            <span style={{ width: valueWidth }}>{`${val} %`}</span>

            <CircularProgress
                aria-hidden='true'
                size={30}
                thickness={6}
                value={val}
                variant='determinate'
                {...rest}
                sx={{
                    color: color,
                    boxShadow: 'inset 0 0 1px 3.5px rgb(229, 224, 224)',
                    borderRadius: '50%',
                    backgroundColor: 'transparent'
                }}
            />
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    color: PropTypes.string,
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default CircularProgressWithLabel;
