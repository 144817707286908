/*import { Link as RouterLink } from "react-router-dom";
import { forwardRef } from "react";*/

/*const LinkBehavior = forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});*/

const link = {
    defaultProps: {
        // LinkComponent: LinkBehavior,
        underline: 'none',
        color: 'inherit',
        fontSize: 'inherit'
    }
};

export default link;
