import borders from '@assets/theme/base/borders';
import colors from '@assets/theme/base/colors';
import pxToRem from '@assets/theme/functions/pxToRem';
import Fade from '@mui/material/Fade';
import boxShadows from '../base/boxShadows';

const { black, white } = colors;
const { borderRadius } = borders;

const tooltip = {
    defaultProps: {
        arrow: true,
        TransitionComponent: Fade
    },

    styleOverrides: {
        tooltip: {
            maxWidth: pxToRem(354),
            backgroundColor: black.main,
            color: white.main,
            fontSize: '1.05rem',
            fontWeight: 500,
            textAlign: 'center',
            /* outline: `2px solid ${white.main}`,
            outlineOffset: '-5.5px', */
            borderRadius: borderRadius.lg,
            opacity: 1,
            padding: `${pxToRem(16)} ${pxToRem(22)}`,
            boxShadow: boxShadows.xl
        },

        arrow: {
            color: black.main
        }
    }
};

export default tooltip;
