import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
//import i18n from '@/i18n.js';

export default function ErrorPage() {
    let error = useRouteError();

    const { t } = useTranslation();

    console.error(error);

    error = error?.statusText || error?.message || error || 'Unknown error';

    if (error?.includes('Failed to fetch dynamically imported module')) {
        return (
            <>
                <h1
                    style={{
                        fontSize: '2.5rem',
                        color: 'white',
                        padding: '3rem'
                    }}
                >
                    {t('versionOutdated')}
                </h1>
            </>
        );
    }

    return (
        <>
            <h1
                style={{
                    fontSize: '2rem',
                    color: 'white',
                    padding: '3rem'
                }}
            >
                {t('unexpectedError')}
            </h1>

            <p
                style={{
                    fontSize: '1.5rem',
                    color: 'white',
                    padding: '3rem',
                    paddingTop: 0
                }}
            >
                <i>{error}</i>
            </p>
        </>
    );
}
