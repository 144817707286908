import { userAtom } from '@/global-store';
import { useAtom } from 'jotai';
import { Navigate } from 'react-router-dom';

function WildcardRoute(props) {
    const [user] = useAtom(userAtom);

    const redirectUrl = props.redirectTo || '/';

    if (!import.meta.env.PROD) alert('WildcardRoute is redirecting to ' + redirectUrl);

    console.info('wildcard route is redirecting', redirectUrl);

    return <Navigate to={user ? redirectUrl : '/authentication/login'} />;
}

export default WildcardRoute;
