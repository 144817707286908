import colors from '@assets/theme/base/colors';
import LinkBehavior from './LinkBehavior';

const { secondary } = colors;

const buttonBase = {
    defaultProps: {
        disableRipple: false,
        LinkComponent: LinkBehavior
    },
    styleOverrides: {
        root: {
            '&:hover, &:focus': {
                outline: `3px solid ${secondary.dark}`
            },
            '&:disabled': {
                cursor: 'not-allowed !important'
            }
        }
    }
};

export default buttonBase;
