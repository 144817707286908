import BlogCard from '@components/BlogCard';
import Grid from '@mui/material/Unstable_Grid2';

function BlogCards({ articles }) {
    return (
        <Grid alignItems='stretch' container p={0} spacing={2.5}>
            {articles.map(article => (
                <Grid key={article.title + article.to} sm={6} xl={3} xs={12}>
                    <BlogCard
                        description={article.description}
                        image={article.image}
                        label={article.label}
                        title={article.title}
                        to={article.to}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default BlogCards;
