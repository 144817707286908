import ImageWithPreview from '@components/ImageWithPreview';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';

/**
 * TitleWithIcon Component.
 *
 * A component to display a title with an optional icon, subtitle, and image preview.
 *
 * @param {object} props - Props to be passed to the component.
 * @param {React.ElementType} [props.icon] - React component to be used as an icon.
 * @param {object} [props.iconProps] - Props to be passed to the icon component.
 * @param {string} [props.title] - Main title text.
 * @param {string|React.Element} [props.subtitle] - Subtitle text.
 * @param {boolean} [props.small=false] - Flag to determine if the component should be rendered in a small size.
 * @param {boolean} [props.large=false] - Flag to determine if the component should be rendered in a large size.
 * @param {string} [props.imageSrc] - Source URL of an image to be shown.
 * @param {boolean} [props.subtitleFullWidthOnMobile=false] - Flag to determine if the subtitle should take full width on mobile view.
 * @param {boolean} [props.h1=false] - Flag to determine if the title should be rendered as an h1 tag. If false, h2 will be used.
 * @param {boolean} [props.fullWidth=false] - Flag to determine if the title should fill up the entire width of the container.
 * will be used.
 *
 * @returns {React.Element} The rendered component.
 */
export default function TitleWithIcon({
    icon,
    iconProps,
    title,
    subtitle,
    small = false,
    large = false,
    imageSrc,
    subtitleFullWidthOnMobile = false,
    h1 = false,
    fullWidth = false,
    useNaturalImageSize = false
}) {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const _subtitleFullWidthOnMobile = subtitleFullWidthOnMobile && mobile;

    if (large && small) console.error('TitleWithIcon received true for both small and large!');

    let avatarSize = 54;
    let iconSize = 34;
    let fontSize = 1.15;
    let gap = 2;

    if (mobile) {
        avatarSize -= 8;
        iconSize -= 6;
        fontSize -= 0.2;
        gap -= 0.5;
    }

    if (small) {
        if (mobile) {
            avatarSize -= 2;
            iconSize -= 2;
            fontSize -= 0.05;
            gap -= 0.2;
        } else {
            avatarSize -= 4;
            iconSize -= 4;
            fontSize -= 0.2;
            gap -= 0.3;
        }
    }

    if (large && subtitle) {
        if (mobile) {
            avatarSize += 3;
            iconSize += 3;
            fontSize += 0.05;
            gap += 0.3;
        } else {
            avatarSize += 8;
            iconSize += 6;
            fontSize += 0.1;
            gap += 1;
        }
    }

    gap = icon ? gap : 0;

    const Icon = icon;

    return (
        <Grid
            alignItems='flex-start'
            container
            flexDirection={{ xs: 'column', md: 'row' }}
            flexWrap={{ xs: 'wrap', xl: 'nowrap' }}
            gap={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
            m={0}
            p={0}
        >
            <Box
                display={fullWidth ? 'flex' : 'inline-flex'}
                flexShrink={1}
                width={fullWidth ? '100%' : 'auto'}
            >
                {icon ? (
                    <Box
                        sx={{
                            'boxShadow': theme.palette.primary.darkGrey,
                            'background': theme.palette.primary.main,
                            'borderRadius': 3,
                            'display': 'flex',
                            'alignItems': 'center',
                            'justifyContent': 'center',
                            'flexGrow': 0,
                            'flexShrink': 0,
                            'width': avatarSize,
                            'height': avatarSize,
                            '& svg': {
                                width: iconSize,
                                height: iconSize
                            }
                        }}
                    >
                        <Icon color={theme.palette.white.main} {...iconProps} />
                    </Box>
                ) : null}

                <Box
                    display={fullWidth ? 'flex' : 'inline-flex'}
                    flexDirection='column'
                    gap={1}
                    justifyContent={imageSrc ? 'flex-start' : 'center'}
                    ml={gap}
                    width={fullWidth ? '100%' : 'auto'}
                >
                    {title ? (
                        <Box
                            component={h1 ? 'h1' : 'h2'}
                            fontSize={fontSize + 0.15 + 'rem'}
                            lineHeight={1}
                            sx={{
                                letterSpacing: large ? '-0.2px' : '-0.1px',
                                wordWrap: 'break-word',
                                hyphens: 'auto'
                            }}
                        >
                            {title}
                        </Box>
                    ) : null}

                    {!_subtitleFullWidthOnMobile && subtitle ? (
                        <Box fontSize={fontSize - 0.1 + 'rem'} lineHeight='1.2 !important'>
                            {subtitle}
                        </Box>
                    ) : null}
                </Box>
            </Box>

            {imageSrc ? (
                <Box mx={{ xs: 'auto', lg: 0 }}>
                    <ImageWithPreview
                        alt={'Image for ' + title}
                        imgSrc={imageSrc}
                        useNaturalImageSize={useNaturalImageSize}
                    />
                </Box>
            ) : null}

            {_subtitleFullWidthOnMobile && subtitle ? (
                <Box fontSize={fontSize - 0.1 + 'rem'} lineHeight='1.2 !important' mt={2}>
                    {subtitle}
                </Box>
            ) : null}
        </Grid>
    );
}

TitleWithIcon.propTypes = {
    h1: PropTypes.bool,
    icon: PropTypes.elementType,
    iconProps: PropTypes.object,
    imageSrc: PropTypes.string,
    large: PropTypes.bool,
    small: PropTypes.bool,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitleFullWidthOnMobile: PropTypes.bool,
    title: PropTypes.string
};
