import { Autocomplete, Box, TextField } from '@mui/material';
import { useMemo } from 'react';
import JiraUserInfo from './JiraUserInfo';

export default function JiraUsersAutocomplete({ defaultUser, users, handleSelection }) {
    let sortedUsers = useMemo(() => {
        if (!users) return [];
        return users?.sort((a, b) => {
            if (a?.active === b?.active) {
                return a?.displayName?.localeCompare(b?.displayName);
            }
            return !Object.prototype.hasOwnProperty.call(a, 'active') || a?.active ? -1 : 1;
        });
    }, [users]);

    return (
        <Autocomplete
            id='jira-users-list'
            isOptionEqualToValue={(option, value) => {
                return option.accountId === value.accountId;
            }}
            sx={{ width: 280 }}
            options={sortedUsers}
            defaultValue={defaultUser}
            autoHighlight
            getOptionLabel={option => option?.displayName}
            onChange={handleSelection}
            renderOption={(props, option) => (
                <Box component='li' {...props} key={option.accountId}>
                    <JiraUserInfo user={option} />
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant='standard'
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'jira-user' // disable autocomplete and autofill
                    }}
                />
            )}
            filterOptions={(options, params) => {
                options = options || [];
                const _inputValue = params.inputValue.trim().toLowerCase();

                if (_inputValue === '') return options;

                const filtered = options.filter(option =>
                    option.displayName.toLowerCase().includes(_inputValue)
                );

                return filtered;
            }}
        />
    );
}
