import BoxWithTitle from '@/components/BoxWithTitle';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { IconCirclePlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const cdnUrl = 'https://cdn.eye-able.com/public/images/';

export default function FunctionPositionForm({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const functionOptions = {
        Bluefilter: {
            title: t('functionPosBlueFilter'),
            image: cdnUrl + 'Eye-Able-Main-Bluefilter.svg'
        },
        Darkmode: {
            title: t('functionPosDarkMode'),
            image: cdnUrl + 'Eye-Able-Main-Nightmode.svg'
        },
        TabNavi: {
            title: t('functionPosTabNav'),
            image: cdnUrl + 'Eye-Able-Main-Keyboard.svg'
        },
        ColorCorrection: {
            title: t('functionPosColorDef'),
            image: cdnUrl + 'Eye-Able-Main-Farbschwaeche.svg'
        },
        HideImages: {
            title: t('functionPosHideImgs'),
            image: cdnUrl + 'Eye-Able-Main-Image.svg'
        },
        LargeCursor: {
            title: t('functionPosLargeCursor'),
            image: cdnUrl + 'eyeable_icon-cursor.svg',
            imageProps: { backgroundColor: '#000' }
        },
        StopAnimation: {
            title: t('functionPosStopAnim'),
            image: cdnUrl + 'eyeable_icon-settings-gif.svg',
            imageProps: { backgroundColor: '#000' }
        }
    };

    const boxWithTitleConfig = [
        {
            filterVal: 'Main',
            title: t('mainMenu')
        },
        {
            filterVal: 'More',
            title: t('moreFunctions'),
            icon: IconCirclePlus
        }
    ];

    return boxWithTitleConfig.map((header, idx) => (
        <Grid key={header.filterVal + idx} lg={6} xs={12}>
            <BoxWithTitle title={header.title}>
                {Object.entries(dataValues)
                    .filter(([, fieldVal]) => fieldVal.value === header.filterVal)
                    .map(fieldEntry => {
                        const fieldKey = fieldEntry[0];
                        const field = functionOptions[fieldKey];
                        const moveToMenuBtnTarget = header.filterVal === 'More' ? 'Main' : 'More';
                        return (
                            <Grid
                                alignContent='center'
                                alignItems='center'
                                display='flex'
                                flexDirection={{ xs: 'column', md: 'row' }}
                                gap={{ xs: 1, md: 2, lg: 4 }}
                                justifyContent='space-between'
                                key={fieldKey}
                                px={3}
                                xs={12}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        alt='Menu Item Icon'
                                        src={field.image}
                                        style={{
                                            height: '2rem',
                                            width: '2rem',
                                            marginRight: '0.5rem',
                                            border: '2px solid black',
                                            borderRadius: '4px',
                                            ...(field.imageProps ?? {})
                                        }}
                                    />

                                    <Typography fontWeight={500}>{field.title}</Typography>
                                </Box>

                                <MoveToMenuButton
                                    handleClicked={() =>
                                        handleInputChange(fieldKey, moveToMenuBtnTarget)
                                    }
                                    targetMenu={
                                        boxWithTitleConfig.find(
                                            config => config.filterVal === moveToMenuBtnTarget
                                        ).title
                                    }
                                />
                            </Grid>
                        );
                    })}
            </BoxWithTitle>
        </Grid>
    ));
}

function MoveToMenuButton({ targetMenu, buttonProps = {}, handleClicked }) {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap'
            }}
        >
            {t('moveTo')}:
            <Button
                color='secondary'
                size='small'
                style={{ marginLeft: 5 }}
                variant='contained'
                {...buttonProps}
                onClick={handleClicked}
            >
                {targetMenu}
            </Button>
        </Box>
    );
}
