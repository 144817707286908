function IconDefinition() {
    return (
        <>
            <defs>
                <linearGradient
                    gradientUnits='userSpaceOnUse'
                    id='A'
                    x1='253.85'
                    x2='253.85'
                    y1='63.7'
                    y2='480.49'
                >
                    <stop offset='0' stopColor='#4f4f4e' />

                    <stop offset='0.26' stopColor='#313130' />

                    <stop offset='0.55' stopColor='#161616' />

                    <stop offset='0.8' stopColor='#060606' />

                    <stop offset='1' />
                </linearGradient>

                <linearGradient
                    gradientTransform='scale(1 -1) rotate(-80.8 -43.072 103.2)'
                    x1='214.9'
                    x2='286.3'
                    xlinkHref='#A'
                    y1='353.49'
                    y2='353.49'
                />

                <linearGradient x1='135.26' x2='365.84' xlinkHref='#A' y1='296.4' y2='296.4' />
            </defs>

            <path
                transform='translate(-59.2 -58.6)'
                fill='currentColor'
                // fill={bgColor}
                d='M201.6 443.2h-33.4c-27.7 0-51.9-9.6-71.9-28.4a101.84 101.84 0 01-30.6-56.2 107.42 107.42 0 01-1.7-19v-52.7l.1-120.5c.1-26.7 9.5-50.3 28.1-70.1a101.58 101.58 0 0155.6-30.7 104 104 0 0120.9-1.9H339c25.9 0 48.8 8.3 68.1 24.6a102 102 0 0134.5 58.2 100.5 100.5 0 012.1 20.8V340a99.13 99.13 0 01-11 46.3c-15.9 30.4-41 49.1-74.7 55.6a95.89 95.89 0 01-17.6 1.6h-87.8z'
            />

            <path
                color='currentColor'
                // fill={color}
                d='M279.9 10c24.4 0 46.2 7.6 64.8 23.4C362 48 372.9 66.6 377.6 88.8a93.42 93.42 0 012 19.7q0 86.4.1 172.7a94.41 94.41 0 01-10.4 44q-22.8 43.65-71.2 53a85.05 85.05 0 01-16.7 1.5H109.2c-26.4 0-49.3-9-68.4-27.1-15.5-14.6-25.1-32.5-29.1-53.5a99.85 99.85 0 01-1.6-18.1l.1-173.2c.1-25.7 9.2-48 26.7-66.7a96.5 96.5 0 0152.9-29.2 102.64 102.64 0 0120-1.8h96.9l73.2-.1m0-10H109.7a114.49 114.49 0 00-22 2 106.79 106.79 0 00-58.2 32.2C10.1 55 .2 79.7.1 107.7L0 228.2v52.7a109.41 109.41 0 001.8 20c4.4 23.2 15.1 43 32.1 58.9 20.9 19.7 46.3 29.8 75.3 29.8h172.3a99.15 99.15 0 0018.6-1.6c35.3-6.8 61.6-26.3 78.2-58.2a103.74 103.74 0 0011.6-48.6V108.5a103.33 103.33 0 00-2.2-21.8 106.53 106.53 0 00-36.2-61C331 8.6 307 0 279.9 0z'
            />

            <circle
                cx='250.6'
                cy='146.4'
                r='35.7'
                fill='currentColor'
                // fill={bgColor}
                transform='rotate(-80.78 186.569 151.888)'
            />

            <path
                transform='translate(-59.2 -58.6)'
                fill='currentColor'
                // fill={bgColor}
                d='M350.6 203.6l-75 6.7c-2.8.2-5.5.4-8.3.4h-33.4a76.87 76.87 0 01-8.3-.4l-75-6.7a14.24 14.24 0 00-2.2 28.4l60.6 5.5a14.25 14.25 0 0113 14.2v16.4a33.8 33.8 0 01-2.5 13l-34.5 88a14.3 14.3 0 0026.1 11.7l33-80.1a7 7 0 0113-.1l33 80.1a14.3 14.3 0 1026.1-11.7l-34.5-87.9a36.61 36.61 0 01-2.5-13v-16.4a14.18 14.18 0 0113-14.2l60.6-5.5a14.24 14.24 0 00-2.2-28.4z'
            />

            <path
                color='currentColor'
                // fill={color}
                d='M191.4 130.7c-23.693 0-42.9-19.207-42.9-42.9s19.207-42.9 42.9-42.9 42.9 19.207 42.9 42.9a42.89 42.89 0 01-42.9 42.9zm0-71.5c-13.69-.038-25.498 9.605-28.197 23.026a28.68 28.68 0 0017.105 32.135c12.641 5.256 27.234.846 34.848-10.531A28.68 28.68 0 00211.6 67.6a29.06 29.06 0 00-20.2-8.4zm52.5 278.6a21.46 21.46 0 01-19.5-12.6l-33.1-80.3-32.7 80.1a21.41 21.41 0 01-37.1 4.1 21.57 21.57 0 01-2.1-21.5l34.4-87.5a26.63 26.63 0 001.9-10.4v-16.4a7.09 7.09 0 00-6.5-7.1l-60.6-5.5c-11.791-.911-20.611-11.209-19.7-23s11.209-20.611 23-19.7l75.1 6.7a97.18 97.18 0 007.7.3h33.4a99.08 99.08 0 007.7-.3l75-6.7h.1c11.791-.911 22.089 7.909 23 19.7s-7.909 22.089-19.7 23l-60.5 5.5a7.09 7.09 0 00-6.5 7.1v16.4a28.29 28.29 0 002 10.4l34.5 87.9a21.36 21.36 0 01-1.8 20.2 22.06 22.06 0 01-18 9.6zm-52.5-107.1a14.11 14.11 0 0113.1 8.8l33 80.1a7.62 7.62 0 003.9 3.6 7.13 7.13 0 009-9.6l-34.6-88.3a42.14 42.14 0 01-3-15.7v-16.4c-.054-11.101 8.438-20.376 19.5-21.3l60.6-5.5a7 7 0 004.9-2.4 6.61 6.61 0 001.7-5.2 7 7 0 00-7.6-6.6l-74.9 6.7a88.33 88.33 0 01-8.9.4h-33.4a87 87 0 01-8.9-.4l-75-6.7a7.12 7.12 0 00-1 14.2l60.7 5.5c11.062.924 19.554 10.199 19.5 21.3v16.4a42.14 42.14 0 01-3 15.7l-34.5 87.9a7.09 7.09 0 00.3 7.3 7.19 7.19 0 006.6 3.2 7 7 0 005.9-4.3l32.9-79.9a14 14 0 0113.2-8.8z'
            />
        </>
    );
}

export function AssistIcon({ styleProps = {} }) {
    return (
        <svg
            preserveAspectRatio='none'
            style={{
                width: '70px',
                height: '70px',
                fill: 'white',
                display: 'block',
                ...styleProps
            }}
            viewBox='0 0 389.9 389.6'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            // style={{ height: iconSize, width: iconSize }}
            aria-label='Eye-Able Assistenzsoftware Logo'
        >
            <IconDefinition />
        </svg>
    );
}
export function AssistIconWithoutId() {
    return (
        <svg
            aria-label='Eye-Able Assistenzsoftware Logo'
            className='mainIconSVG'
            preserveAspectRatio='none'
            style={{ height: '100%', width: '100%', fill: 'white' }}
            viewBox='0 0 389.9 389.6'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <IconDefinition />
        </svg>
    );
}
