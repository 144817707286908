import { BookmarkCategories } from '@helpers/constants.js';
import {
    IconBoxMultiple,
    IconBrowserCheck,
    IconBrowserX,
    IconFileCheck,
    IconFileUpload,
    IconSortAscending,
    IconTextSpellcheck,
    IconUnlink,
    IconZoomExclamation
} from '@tabler/icons-react';

export const todoCategoryProps = {
    [BookmarkCategories.ISSUE]: {
        i18nLabelKey: 'issue',
        icon: IconZoomExclamation,
        url(bookmark) {
            return '/report/issues/issue/' + bookmark.filterId;
        }
    },
    [BookmarkCategories.BROKEN_LINKS]: {
        label: 'Broken Links',
        icon: IconUnlink,
        url(bookmark) {
            return '/report/analysis/broken-links?url=' + bookmark.filterId;
        }
    },
    [BookmarkCategories.PDFS_FOUND]: {
        i18nLabelKey: 'foundPdfs',
        icon: IconFileCheck,
        url(bookmark) {
            return '/report/pdfs/found/pdf/' + bookmark.filterId;
        }
    },
    [BookmarkCategories.PDFS_UPLOAD]: {
        i18nLabelKey: 'userPdfs',
        icon: IconFileUpload,
        url(bookmark) {
            return '/report/pdfs/upload/pdf/' + bookmark.filterId;
        }
    },
    [BookmarkCategories.SITE]: {
        i18nLabelKey: 'pages',
        icon: IconBrowserCheck,
        url(bookmark) {
            return '/report/pages/page/' + bookmark.props?.pageIndex;
        }
    },
    [BookmarkCategories.SITE_ISSUE]: {
        i18nLabelKey: 'pageDetails',
        icon: IconBrowserX,
        url(bookmark) {
            return `/report/pages/page/${bookmark.props?.pageIndex}?scrollTo=${bookmark.props?.ruleNum}`;
        }
    },
    [BookmarkCategories.TOP_ISSUE]: {
        i18nLabelKey: 'topErrorsTableHeading',
        icon: IconSortAscending,
        url(bookmark) {
            return '/report/issues?scrollTo=topIssues_' + bookmark.filterId;
        }
    },
    [BookmarkCategories.SPELLCHECKER_MISTAKE]: {
        i18nLabelKey: 'spellCheckMistake',
        icon: IconTextSpellcheck,
        url(/*bookmark*/) {
            return '/report/analysis/spellchecker/';
        }
    },
    [BookmarkCategories.SPELLCHECKER_PAGE]: {
        i18nLabelKey: 'spellCheckPages',
        icon: IconBoxMultiple,
        url(bookmark) {
            return '/report/analysis/spellchecker/page/' + bookmark.props?.pageIndex;
        }
    }
};

export function isTodoValid(todo) {
    if (todo.resolved) return true;
    todo.filterId = todo.filterId ?? todo.props?.filterId ?? todo.title;
    // filter out bookmarks with no valid url
    const goToPage = todoCategoryProps[todo.category]?.url(todo);
    return !(!goToPage || goToPage.includes('undefined'));
}
