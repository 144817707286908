import colors from '@assets/theme/base/colors';
import pxToRem from '@assets/theme/functions/pxToRem';

const { dark } = colors;

const baseProperties = {
    fontFamily: 'Poppins, Arial, sans-serif',
    fontWeightLighter: 300,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontSizeXXS: pxToRem(10.5),
    fontSizeXS: pxToRem(11.5),
    fontSizeSM: pxToRem(13),
    fontSizeMD: pxToRem(14.5),
    fontSizeLG: pxToRem(16),
    fontSizeXL: pxToRem(17.5),
    fontSize2XL: pxToRem(20),
    fontSize3XL: pxToRem(24)
};

const baseHeadingProperties = {
    fontFamily: baseProperties.fontFamily,
    color: dark.main,
    fontWeight: baseProperties.fontWeightBold,
    letterSpacing: '-0.3px'
};

const caption = {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.25,
    letterSpacing: '0.15px'
};

const typography = {
    fontFamily: baseProperties.fontFamily,
    fontWeightLighter: baseProperties.fontWeightLighter,
    fontWeightLight: baseProperties.fontWeightLight,
    fontWeightRegular: baseProperties.fontWeightRegular,
    fontWeightMedium: baseProperties.fontWeightMedium,
    fontWeightBold: baseProperties.fontWeightBold,

    h1: {
        ...baseHeadingProperties,
        fontSize: baseProperties.fontSize3XL,
        lineHeight: 1.25
    },

    h2: {
        ...baseHeadingProperties,
        fontSize: baseProperties.fontSize2XL,
        lineHeight: 1.3
    },

    h3: {
        ...baseHeadingProperties,
        fontSize: baseProperties.fontSizeXL,
        lineHeight: 1.35,
        letterSpacing: '-0.2px'
    },

    h4: {
        ...baseHeadingProperties,
        fontSize: baseProperties.fontSizeLG,
        lineHeight: 1.4,
        letterSpacing: '-0.1px'
    },

    h5: {
        ...baseHeadingProperties,
        fontSize: baseProperties.fontSizeMD,
        lineHeight: 1.5,
        letterSpacing: '0px'
    },

    h6: {
        ...baseHeadingProperties,
        fontSize: baseProperties.fontSizeMD,
        lineHeight: 1.5,
        letterSpacing: '0.1px'
    },

    subtitle1: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeLG,
        fontWeight: baseProperties.fontWeightMedium,
        lineHeight: 1.5
    },

    subtitle2: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeMD,
        fontWeight: baseProperties.fontWeightMedium,
        lineHeight: 1.5
    },

    body1: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeLG,
        fontWeight: baseProperties.fontWeightRegular,
        lineHeight: 1.55,
        letterSpacing: '0.15px'
    },

    body2: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeMD,
        fontWeight: baseProperties.fontWeightRegular,
        lineHeight: 1.6,
        letterSpacing: '0.1px'
    },

    button: {
        fontFamily: baseProperties.fontFamily,
        fontWeight: baseProperties.fontWeightLight,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        letterSpacing: '1.1px'
    },

    caption: caption,

    overline: {
        fontFamily: baseProperties.fontFamily,
        fontSize: baseProperties.fontSizeXS,
        letterSpacing: '0.5px'
    },

    size: {
        'xxs': baseProperties.fontSizeXXS,
        'xs': baseProperties.fontSizeXS,
        'sm': baseProperties.fontSizeSM,
        'md': baseProperties.fontSizeMD,
        'lg': baseProperties.fontSizeLG,
        'xl': baseProperties.fontSizeXL,
        '2xl': baseProperties.fontSize2XL,
        '3xl': baseProperties.fontSize3XL,
        'xxl': baseProperties.fontSize2XL,
        'xxxl': baseProperties.fontSize3XL
    },

    lineHeight: {
        sm: 1.25,
        md: 1.5,
        lg: 2
    },

    menuCaption: {
        ...caption,
        fontSize: '0.9rem',
        fontWeight: 700,
        color: 'inherit',
        padding: '8px 6px',
        textTransform: 'capitalize',
        letterSpacing: '0.5px',
        margin: 0
    },

    subMenuCaption: {
        ...caption,
        fontSize: '0.75rem',
        color: 'inherit',
        textTransform: 'capitalize',
        margin: 0,
        marginBottom: '2px'
    }
};

export default typography;
