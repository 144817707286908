import yupLocales from '@/assets/yupLocales';
import { makeRequired, makeValidateSync } from 'mui-rff';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

// returns the validate and required functions for the form that is used by react-final-form and mui-rff
// it also sets the yup locale to the current i18n language
export default function useFormSchema(yupSchema) {
    const { i18n } = useTranslation();
    const lang = i18n.resolvedLanguage;

    const { validate, required } = useMemo(() => {
        setLocale(yupLocales[lang]);
        const validate = makeValidateSync(yupSchema);
        const required = makeRequired(yupSchema);
        return { validate, required };
    }, [lang, yupSchema]);

    return { validate, required };
}
