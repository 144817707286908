import { AppBar, Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ColoredDot from '@pages/report/Lighthouse/components/ColoredDot.jsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const StyledTab = styled(props => <Tab {...props} />)(({ theme }) => ({
    'borderRadius': theme.borders.borderRadius.xl,
    'm': 0,
    'mr': '4px',
    'alignItems': 'flex-center',
    'minWidth': '130px !important',
    'backgroundColor': 'transparent' + '!important',
    // padding: '9px 14px !important',
    'border': 'none !important',
    'color': '#fff !important',
    'lineHeight': '1 !important',
    '& .MuiTab-root': {
        fontSize: '1.05rem !important',
        color: '#fff !important',
        minWidth: '130px !important',
        backgroundColor: 'transparent' + '!important'
    },

    '&.Mui-selected': {
        color: `#000 !important`,
        backgroundColor: theme.palette.secondary.main + '!important',
        fontWeight: 700
    },

    '&:hover': {
        'outline': '2px solid white',
        'outlineOffset': '-1px',
        'backgroundColor': '#fff !important',
        'color': '#000 !important',
        '& .MuiListItemIcon-root': {
            color: '#000 !important'
        }
    },

    '&:focus': {
        outline: '2px solid white',
        outlineOffset: '-1px'
    }
}));

export const StyledTabs = styled(props => <Tabs {...props} />)(({ theme }) => ({
    'padding': '5px',
    'borderRadius': theme.borders.borderRadius.xxl,
    'background': `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    '& .MuiTabs-root': {
        gap: 2 + '!important',
        backgroundColor: theme.palette.primary.main + '!important'
    },
    '& .MuiTabs-flexContainer': {
        rowGap: '5px' + '!important'
    },
    '& .MuiTabScrollButton-root': {
        'fontWeight': 'bold',
        '& svg': {
            fill: '#ffdb50' + '!important',
            fontSize: '1.5rem' + '!important'
        }
    }
}));

export function TabPanel({ children, value, index, tabName, ...other }) {
    return (
        <div
            aria-labelledby={`tabpanel-${tabName}`}
            hidden={value !== index}
            id={`tab-${index}`}
            role='tabpanel'
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

/**
 * Represents an array of tabs items.
 * @typedef {Object} TabItem
 * @property {string} title - The title of the tab.
 * @property {ReactElement|string} content - The JSX content or a string.
 */

/**
 * @param props {Object}
 * @param props.tabs{TabItem[]}
 * @returns {Element}
 */
function TabsWithPanels({ tabs = [], tabsProps = {} }) {
    const [activeTab, setActiveTab] = useState(0);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const variant = isSmallScreen ? 'scrollable' : 'fullWidth';

    return (
        <>
            <AppBar position='static'>
                <StyledTabs
                    allowScrollButtonsMobile
                    indicatorColor='secondary'
                    onChange={(event, newIndex) => setActiveTab(newIndex)}
                    scrollButtons
                    textColor='inherit'
                    value={activeTab}
                    variant={variant}
                    {...tabsProps}
                >
                    {tabs.map((tab, idx) => {
                        const label = tab.showDot ? (
                            <Box alignItems='center' display='flex'>
                                <Box fontWeight={600}>{tab.title}</Box>
                                <Box width='25px'>
                                    <ColoredDot color='#e80000' />
                                </Box>
                            </Box>
                        ) : (
                            tab.title
                        );
                        return (
                            <StyledTab
                                aria-controls={`tabpanel-${idx}`}
                                id={`tab-${idx}`}
                                key={tab.title}
                                label={label}
                                onClick={() => tab.onClick?.()}
                            />
                        );
                    })}
                </StyledTabs>
            </AppBar>

            {tabs.map((tab, idx) => {
                return (
                    <TabPanel dir={theme.direction} index={idx} key={tab.title} value={activeTab}>
                        {tab.content}
                    </TabPanel>
                );
            })}
        </>
    );
}

TabsWithPanels.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
            onClick: PropTypes.func
        })
    ).isRequired
};

export default TabsWithPanels;
