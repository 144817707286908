import BoxWithTitle from '@/components/BoxWithTitle';
import CustomTextField from '@components/CustomTextField';
import InputHelperText from '@components/InputHelperText';
import ReactTable from '@components/ReactTable';
import SupportPopupWithContainer from '@components/SupportPopupWithContainer.jsx';
import { Box, Button, IconButton, InputLabel, Link, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { IconMessages, IconSpeakerphone, IconTrashFilled } from '@tabler/icons-react';
import { MuiChipsInput } from 'mui-chips-input';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReadAloudForm({ dataValues, extraValues, handleInputChange }) {
    return (
        <>
            {/* Automatic Read Aloud */}
            <Grid xs={12}>
                <AutoReadAloudCard dataValues={dataValues} handleInputChange={handleInputChange} />
            </Grid>

            <Grid xs={12}>
                <DictionaryCard dataValues={dataValues} handleInputChange={handleInputChange} />
            </Grid>

            <Grid xs={12}>
                <CloudVoicesCard
                    azureMode={extraValues?.useAzureVoices}
                    dataValues={dataValues}
                    handleInputChange={handleInputChange}
                />
            </Grid>
        </>
    );
}

function AutoReadAloudCard({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const chipInputFields = {
        sRStartElement: {
            title: t('astCustomFSRStartElmTitle'),
            description: t('astCustomFSRStartElmDesc'),
            errorMsg: ''
        },
        sRTextBlock: {
            title: t('astCustomFSRTextBlockTitle'),
            description: t('astCustomFSRTextBlockDesc'),
            errorMsg: ''
        },
        blacklistScreenReader: {
            title: t('astCustomFBlklstScrReadTitle'),
            description: t('astCustomFBlklstScrReadDesc'),
            errorMsg: ''
        }
    };

    function isValidCssSelector(selector) {
        try {
            document.querySelector(selector);
            return true;
        } catch (error) {
            return false;
        }
    }

    const handleChipsChange = (fieldKey, newChips) => {
        const validChips = newChips.filter(chip => isValidCssSelector(chip));
        handleInputChange(fieldKey, [...new Set(validChips)].join());
    };

    const getChipsListFromStr = strValue => {
        let chips = [];
        if (strValue.trim() !== '') chips = strValue.split(',');
        return chips;
    };

    return (
        <BoxWithTitle title={t('astCustomGAutoReadAloudTitle')}>
            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#333'
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: t('astCustomGAutoReadAloudDesc') }} />

                <Link
                    href='https://eye-able.com/funktionsweise-screenreader/'
                    sx={{ fontWeight: 400, color: '#555', marginLeft: '5px', fontStyle: 'italic' }}
                    target='_blank'
                    underline='hover'
                >
                    https://eye-able.com/funktionsweise-screenreader/
                </Link>
            </Box>

            <Grid container spacing={4}>
                {Object.entries(chipInputFields).map(([fieldKey, field]) => {
                    return (
                        <Grid key={fieldKey} lg={6} xs={12}>
                            <InputLabel htmlFor={fieldKey} sx={{ fontSize: '.9rem' }}>
                                {field.title}
                            </InputLabel>

                            <MuiChipsInput
                                InputLabelProps={{ style: { fontSize: '1rem' } }}
                                error={dataValues[fieldKey].error}
                                fullWidth
                                helperText={dataValues[fieldKey].error ? field.errorMsg : ''}
                                id={fieldKey}
                                name={fieldKey}
                                onChange={newChips => handleChipsChange(fieldKey, newChips)}
                                placeholder={t('chipInputPlaceHolder')}
                                value={getChipsListFromStr(dataValues[fieldKey].value)}
                                variant='filled'
                            />

                            <InputHelperText text={field.description} />
                        </Grid>
                    );
                })}
            </Grid>

            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#333'
                }}
            >
                {t('cssSelectorsInfoLink')}

                <Link
                    href='https://www.w3schools.com/cssref/css_selectors.php'
                    sx={{ fontWeight: 400, color: '#555', marginLeft: '5px', fontStyle: 'italic' }}
                    target='_blank'
                    underline='hover'
                >
                    https://www.w3schools.com/cssref/css_selectors.php
                </Link>
            </Box>
        </BoxWithTitle>
    );
}

function CloudVoicesCard({ dataValues, azureMode, handleInputChange }) {
    const { t } = useTranslation();

    const [phoneticWord, setPhoneticWord] = useState('');
    const [phoneticVal, setPhoneticVal] = useState('');

    const phonaticPronounciation = {
        phoneticWordText: {
            title: t('word'),
            description: '',
            errorMsg: t('cantEmptyWarning'),
            value: phoneticWord,
            setValue: setPhoneticWord
        },
        phoneticValue: {
            title: t('pronunciation'),
            description: '',
            errorMsg: t('cantEmptyWarning'),
            value: phoneticVal,
            setValue: setPhoneticVal
        }
    };

    const columns = useMemo(
        () => [
            {
                id: 'phWord',
                header: t('word'),
                accessorKey: 'phWord'
            },
            {
                id: 'phVal',
                header: t('pronunciation'),
                accessorKey: 'phVal'
            }
        ],
        [t]
    );

    const tableData = useMemo(() => {
        if (!dataValues?.srPhoentic?.value) return [];

        const rows = [];

        for (const [phWord, phVal] of Object.entries(dataValues.srPhoentic.value)) {
            rows.push({
                phWord,
                phVal
            });
        }

        return rows;
    }, [dataValues]);

    const handleChange = (value, setter) => {
        setter(value);
    };

    const handleAddPronounciation = () => {
        const temp = dataValues.srPhoentic.value;
        const updatedPhoneticVal = structuredClone(temp);
        updatedPhoneticVal[phoneticWord] = phoneticVal;
        handleInputChange('srPhoentic', updatedPhoneticVal);
        setPhoneticWord('');
        setPhoneticVal('');
    };

    const handleDeletePronounciation = prop => {
        const temp = dataValues.srPhoentic.value;
        const updatedPhoneticVal = structuredClone(temp);
        delete updatedPhoneticVal[prop];
        handleInputChange('srPhoentic', updatedPhoneticVal);
    };

    return (
        <BoxWithTitle icon={IconSpeakerphone} title={t('astCustomGCloudVoicesTitle')}>
            <Box sx={{ zIndex: 99 }}>
                <Box
                    component='h3'
                    fontSize={1 + 'rem'}
                    fontWeight={500}
                    lineHeight={1.5}
                    sx={{
                        wordBreak: 'break-word',
                        letterSpacing: '0.2px',
                        my: 2,
                        color: '#333'
                    }}
                >
                    {t('astCustomGCloudVoicesSubtitle')}
                </Box>

                <Grid alignItems='center' columns={{ xs: 12, lg: 11 }} container spacing={2}>
                    {Object.entries(phonaticPronounciation).map(([fieldKey, field]) => (
                        <Grid key={fieldKey} lg={4} xs={12}>
                            <CustomTextField
                                fieldKey={field.key}
                                handleChange={e => {
                                    handleChange(e.target.value, field.setValue);
                                }}
                                label={field.title}
                                value={field.value}
                            />
                        </Grid>
                    ))}

                    <Grid lg={3} xs={12}>
                        <Button
                            disabled={phoneticWord.trim() === '' || phoneticVal.trim() === ''}
                            fullWidth
                            onClick={handleAddPronounciation}
                            size='large'
                            variant='contained'
                        >
                            {t('addPronounciation')}
                        </Button>
                    </Grid>

                    <Grid xs={12}>
                        <InputHelperText text={t('astCustomFSrPhoenticTooltip')} />
                    </Grid>
                </Grid>

                <ReactTable
                    columns={columns}
                    data={tableData}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center'
                            },
                            size: 80
                        }
                    }}
                    enableRowActions
                    positionActionsColumn='last'
                    renderRowActions={({ row }) => (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip arrow placement='right' title={t('delete')}>
                                <IconButton
                                    onClick={() => {
                                        handleDeletePronounciation(row.getValue('phWord'));
                                    }}
                                >
                                    <IconTrashFilled />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Box>

            {!azureMode && (
                <Box
                    sx={theme => ({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        borderRadius: theme.borders.borderRadius.xl,
                        background: '#33333399',
                        width: '100%',
                        height: '100%',
                        zIndex: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    })}
                >
                    <Box sx={{ width: '90%' }}>
                        <SupportPopupWithContainer containerText={t('optionNAContactSupport')} />
                    </Box>
                </Box>
            )}
        </BoxWithTitle>
    );
}

function DictionaryCard({ dataValues, handleInputChange }) {
    const { t } = useTranslation();

    const [abbr, setAbbr] = useState('');
    const [fullWord, setFullWord] = useState('');

    const phonaticPronounciation = {
        phoneticWordText: {
            title: t('abbreviation'),
            description: '',
            errorMsg: t('cantEmptyWarning'),
            value: abbr,
            setValue: setAbbr
        },
        phoneticValue: {
            title: t('replaceWith'),
            description: '',
            errorMsg: t('cantEmptyWarning'),
            value: fullWord,
            setValue: setFullWord
        }
    };

    const columns = useMemo(
        () => [
            {
                id: 'abbrWord',
                header: t('abbreviation'),
                accessorKey: 'abbrWord'
            },
            {
                id: 'replacementWord',
                header: t('replaceWith'),
                accessorKey: 'replacementWord'
            }
        ],
        [t]
    );

    const tableData = useMemo(() => {
        if (!dataValues?.srDictionary?.value) return [];

        const rows = [];

        for (const [abbrWord, replacementWord] of Object.entries(dataValues.srDictionary.value)) {
            rows.push({
                abbrWord,
                replacementWord
            });
        }

        return rows;
    }, [dataValues]);

    const handleChange = (value, setter) => {
        setter(value);
    };

    const handleAddWord = () => {
        const temp = dataValues.srDictionary.value;
        const updateDictionary = structuredClone(temp);
        updateDictionary[abbr] = fullWord;
        handleInputChange('srDictionary', updateDictionary);
        setAbbr('');
        setFullWord('');
    };

    const handleDeleteWord = key => {
        const temp = dataValues.srDictionary.value;
        const updateDictionary = structuredClone(temp);
        delete updateDictionary[key];
        handleInputChange('srDictionary', updateDictionary);
    };

    return (
        <BoxWithTitle icon={IconMessages} title={t('astCustomGAdjustPronounTitle')}>
            <Box
                component='h3'
                fontSize={1 + 'rem'}
                fontWeight={500}
                lineHeight={1.5}
                sx={{
                    wordBreak: 'break-word',
                    letterSpacing: '0.2px',
                    my: 2,
                    color: '#333'
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: t('astCustomGAdjustPronounSubtitle') }} />
            </Box>

            <Grid alignItems='flex-end' columns={{ xs: 12, lg: 11 }} container spacing={2} mb={1.5}>
                {Object.entries(phonaticPronounciation).map(([fieldKey, field]) => (
                    <Grid key={fieldKey} lg={4} xs={12}>
                        <CustomTextField
                            fieldKey={field.key}
                            handleChange={e => handleChange(e.target.value, field.setValue)}
                            label={field.title}
                            value={field.value}
                        />
                    </Grid>
                ))}

                <Grid lg={3} xs={12}>
                    <Button
                        disabled={abbr.trim() === '' || fullWord.trim() === ''}
                        fullWidth
                        onClick={handleAddWord}
                        size='large'
                        variant='contained'
                        sx={{
                            mb: '2.5px'
                        }}
                    >
                        {t('addToList')}
                    </Button>
                </Grid>

                <Grid xs={12}>
                    <InputHelperText text={t('astCustomFsrDictDesc')} />
                </Grid>
            </Grid>

            <Box borderRadius={1.5} border='1px solid #f0f0f0' overflow={'hidden'}>
                <ReactTable
                    columns={columns}
                    data={tableData}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center'
                            },
                            size: 80
                        }
                    }}
                    enableRowActions
                    positionActionsColumn='last'
                    renderRowActions={({ row }) => (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip arrow placement='right' title={t('delete')}>
                                <IconButton
                                    onClick={() => {
                                        handleDeleteWord(row.getValue('abbrWord'));
                                    }}
                                >
                                    <IconTrashFilled />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Box>
        </BoxWithTitle>
    );
}
