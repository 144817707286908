import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'Dieses Feld';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} ist ungültig`,
        required: ({ path }) => `${getFieldName(path)} ist ein Pflichtfeld`,
        defined: ({ path }) => `${getFieldName(path)} muss definiert werden`,
        notNull: ({ path }) => `${getFieldName(path)} darf nicht leer sein`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} muss einer der folgenden Werte sein: ${values}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} darf nicht einer der folgenden Werte sein: ${values}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} muss ein \`${type}\` Typ sein, ` +
                `Aber der Endwert war: \`${printValue(value, true)}\`` +
                (isCast ? ` (aus dem Wert \`${printValue(originalValue, true)}\` \`).` : `.`);

            if (value === null) {
                msg +=
                    `\n Wenn "null" als leerer Wert gedacht ist, markieren Sie das Schema als` +
                    ` `.nullable()``;
            }

            return msg;
        }
    },
    string: {
        length: ({ path }) => `${getFieldName(path)}  muss genau ${length} Zeichen lang sein`,
        min: ({ path, min }) => `${getFieldName(path)}  muss mindestens ${min} Zeichen lang sein`,
        max: ({ path, max }) => `${getFieldName(path)}  darf höchstens ${max} Zeichen lang sein`,
        matches: ({ path, regex }) =>
            `${getFieldName(path)}  muss folgendes Muster haben: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)}  muss eine gültige E-Mail sein`,
        url: ({ path }) => `${getFieldName(path)}  muss eine gültige URL sein`,
        uuid: ({ path }) => `${getFieldName(path)}  muss eine gültige UUID sein`,
        trim: ({ path }) => `${getFieldName(path)}  muss eine Zeichenfolge ohne Leerzeichen sein`,
        lowercase: ({ path }) => `${getFieldName(path)}  muss in Kleinbuchstaben geschrieben sein`,
        uppercase: ({ path }) => `${getFieldName(path)}  muss in Großbuchstaben geschrieben sein`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)}  muss größer oder gleich ${min} sein`,
        max: ({ path, max }) => `${getFieldName(path)}  muss geringer oder gleich ${max} sein`,
        lessThan: ({ path, less }) => `${getFieldName(path)}  muss kleiner als ${less} sein`,
        moreThan: ({ path, more }) => `${getFieldName(path)}  muss größer als ${more} sein`,
        positive: ({ path }) => `${getFieldName(path)}  muss eine positive Zahl sein`,
        negative: ({ path }) => `${getFieldName(path)}  muss eine negative Zahl sein`,
        integer: ({ path }) => `${getFieldName(path)}  muss eine ganze Zahl sein`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)}  muss nach ${min} sein`,
        max: ({ path, max }) => `${getFieldName(path)}  muss vor ${max} sein`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)}  muss ${value} sein`
    },
    object: {
        noUnknown: ({ path }) => `${getFieldName(path)}  kann keine unbekannten Werte enthalten`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)}  muss mindestens ${min} Einträge haben`,
        max: ({ path, max }) => `${getFieldName(path)}  darf höchstens ${max} Einträge haben`,
        length: ({ path }) => `${getFieldName(path)}  muss ${length} Einträge haben`
    }
};
