import { userAtom } from '@/global-store';
import useLogoutFn from '@/hooks/useLogoutFn';
import { useChangePasswordMutation } from '@http/queries.js';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { IconEye, IconEyeOff, IconKey, IconX } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

export default function ChangePasswordForm({ onCancel }) {
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();

    const [user] = useAtom(userAtom);

    const hasPassword = user && user.hasPassword;

    const [params] = useSearchParams();

    const token = params.get('token');

    const changePasswordMutation = useChangePasswordMutation();

    const logoutFn = useLogoutFn();

    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    });

    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    });

    function handleChange(e) {
        const { name, value } = e.target;

        setFormData(prevFormData => {
            const newFormData = { ...prevFormData, [name]: value };
            // Directly validate form after state update
            validateForm(name, newFormData);
            return newFormData;
        });
    }

    function getPasswordError(pw) {
        if (!pw) return t('passwordRequired');

        // check for invalid characters
        if (!pw.match(/[\p{Letter}\p{Mark}0-9!@#$%&*-]+/gu)) {
            return t('passwordInvalidCharacters');
        }

        // check if the field has at least 8 characters and max 32
        if (pw.length < 8 || pw.length > 32) {
            return t('passwordLengthError');
        }

        return false;
    }

    function validateForm(fieldName, newFormData) {
        let valid = true;
        let tempErrors = { ...errors };

        if (!newFormData) newFormData = formData;

        console.log('Validating form:', fieldName, newFormData);

        for (const [key, value] of Object.entries(newFormData)) {
            if ((token || !hasPassword) && key === 'currentPassword') continue;

            if (fieldName && fieldName !== key) continue;

            const error = getPasswordError(value);
            if (error) {
                tempErrors[key] = error;
                valid = false;
            } else {
                tempErrors[key] = '';
            }
        }

        if (
            (!fieldName || fieldName === 'newPassword' || fieldName === 'newPasswordRepeat') &&
            newFormData.newPasswordRepeat &&
            newFormData.newPassword !== newFormData.newPasswordRepeat
        ) {
            tempErrors.newPasswordRepeat = t('passwordsDontMatch');
            valid = false;
        }

        console.log('Errors:', tempErrors);

        setErrors(tempErrors);
        return valid;
    }

    async function handleSubmit(e) {
        e?.preventDefault();

        setSubmitting(true);

        if (validateForm()) {
            try {
                await changePasswordMutation.mutateAsync({
                    currentPassword: formData.currentPassword,
                    newPassword: formData.newPassword,
                    token
                });

                console.log('Password change successful');

                setError(null);

                setErrors({
                    currentPassword: '',
                    newPassword: '',
                    newPasswordRepeat: ''
                });

                alert(t('passwordChangeSuccess'));

                // logout or redirect to login page
                await logoutFn();
            } catch (error) {
                console.error('Password change error:', error);
                setError(t('errorTryAgain'));
            }
        }

        setSubmitting(false);
    }

    if (!user && !token) {
        console.info('You must be logged in or have a reset token to change your password');
        if (!import.meta.env.PROD)
            alert('You must be logged in or have a reset token to change your password');
        return <Navigate to='/' />;
    }

    return (
        <form onSubmit={handleSubmit}>
            {token || !hasPassword ? null : (
                <>
                    <Box fontWeight={600}>{t('currentPassword')}:</Box>

                    <TextField
                        autoComplete='current-password'
                        error={!!errors.currentPassword}
                        fullWidth
                        helperText={errors.currentPassword}
                        id='current-password'
                        margin='normal'
                        name='currentPassword'
                        onBlur={() => validateForm('currentPassword')}
                        onChange={handleChange}
                        onInput={handleChange}
                        sx={{
                            mt: 0.75,
                            mb: 4.5
                        }}
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Tooltip
                                        title={showPassword ? t('hidePassword') : t('showPassword')}
                                        placement='top'
                                    >
                                        <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                            {showPassword ? <IconEyeOff /> : <IconEye />}
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                </>
            )}

            <Box fontWeight={600}>{t('newPassword')}:</Box>

            <TextField
                autoComplete='new-password'
                error={!!errors.newPassword}
                fullWidth
                helperText={errors.newPassword}
                id='new-password'
                margin='normal'
                name='newPassword'
                onBlur={() => validateForm('newPassword')}
                onChange={handleChange}
                onInput={handleChange}
                sx={{
                    mt: 0.75,
                    mb: 4.5
                }}
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                variant='outlined'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Tooltip
                                title={showPassword ? t('hidePassword') : t('showPassword')}
                                placement='top'
                            >
                                <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                    {showPassword ? <IconEyeOff /> : <IconEye />}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
            />

            <Box fontWeight={600}>{t('newPasswordRepeat')}:</Box>

            <TextField
                autoComplete='new-password'
                error={!!errors.newPasswordRepeat}
                fullWidth
                helperText={errors.newPasswordRepeat}
                id='new-password-repeat'
                margin='normal'
                name='newPasswordRepeat'
                onBlur={() => validateForm('newPasswordRepeat')}
                onChange={handleChange}
                onInput={handleChange}
                sx={{
                    mt: 0.75,
                    mb: 4
                }}
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                variant='outlined'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Tooltip
                                title={showPassword ? t('hidePassword') : t('showPassword')}
                                placement='top'
                            >
                                <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                    {showPassword ? <IconEyeOff /> : <IconEye />}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
            />

            {error ? (
                <Typography color='#b60000' mb={2} variant='h3'>
                    {error}
                </Typography>
            ) : null}

            {!hasPassword ? null : (
                <Button
                    onClick={() => onCancel?.()}
                    fullWidth
                    startIcon={<IconX />}
                    variant='outlined'
                    sx={{
                        mt: 2
                    }}
                >
                    {t('cancel')}
                </Button>
            )}

            <LoadingButton
                fullWidth
                loading={submitting}
                startIcon={<IconKey />}
                type='submit'
                variant='contained'
                size='large'
                sx={{
                    mt: 2
                }}
                /*sx={{
            background: buttonGradient
          }}*/
            >
                {t('changePassword')}
            </LoadingButton>
        </form>
    );
}
