//import Grid from "@mui/material/Unstable_Grid2"; dont use it here it will break the dev server
import CircularProgressWithLabel from '@/components/CircularProgressWithLabel';
import LevelIcon from '@/components/LevelIcon';
import LoadingSpinner from '@/components/LoadingSpinner';
import ReactTable from '@/components/ReactTable';
import { currentAuditEntryIdAtom, demoModeAtom, reportDemoModeAtom } from '@/global-store';
import ProgressBarColors from '@/helpers/progressbar-colors';
import { useFetchReportDataForAllDomains } from '@/http/queries';
import ContentCard from '@components/ContentCard';
import MainBlogArticles from '@components/MainBlogArticles.jsx';
import ProductCards from '@components/ProductCards';
import UserTour from '@components/UserTour.jsx';
import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { IconHome2, IconReport } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

/**
 * Homepage with widgets
 */
export default function Home() {
    const [demoMode] = useAtom(demoModeAtom);
    const [reportDemoMode] = useAtom(reportDemoModeAtom);
    const [, setCurrentAuditEntryId] = useAtom(currentAuditEntryIdAtom);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

    const { t } = useTranslation();

    const { AllReportsData, isLoading, isError } = useFetchReportDataForAllDomains();

    const showReportWidgets = Boolean(
        !reportDemoMode &&
            !demoMode &&
            !isLoading &&
            !isError &&
            AllReportsData &&
            Object?.keys?.(AllReportsData)?.length
    );

    const steps = useMemo(() => {
        const desktopOnlySteps = [
            {
                target: '#collapse-assist',
                content: t('dashboardGuideHomeStep3'),
                disableBeacon: true
            },
            {
                target: '#item-audit',
                content: t('dashboardGuideHomeStep4'),
                disableBeacon: true
            },
            {
                target: '#collapse-report',
                content: t('dashboardGuideHomeStep5'),
                disableBeacon: true
            },
            {
                target: '#collapse-report-analysis',
                content: t('dashboardGuideHomeStep6'),
                disableBeacon: true
            },
            {
                target: '#item-ally',
                content: t('dashboardGuideHomeStep8'),
                disableBeacon: true
            },
            {
                target: '#item-contrast-check',
                content: t('dashboardGuideHomeStep7'),
                disableBeacon: true
            },
            {
                target: '#support-popup',
                content: t('dashboardGuideHomeStep9'),
                disableBeacon: true
            }
        ];

        const _steps = [
            {
                content: t('dashboardGuideHomeWelcome'),
                title: t('dashboardGuideTitle'),
                placement: 'center',
                target: 'body',
                disableBeacon: true
            },
            {
                target: '#header-right-section',
                content: t('dashboardGuideHomeStep1'),
                disableBeacon: true
            },
            showReportWidgets
                ? {
                      target: '#homeAreaBox',
                      content: t('dashboardGuideHomeStep2Widget'),
                      placement: 'bottom',
                      disableBeacon: true
                  }
                : {
                      target: '#homeAreaBox',
                      content: t('dashboardGuideHomeStep2'),
                      placement: 'bottom',
                      disableBeacon: true
                  }
        ];

        if (isDesktop) {
            _steps.push(...desktopOnlySteps);
        } else {
            _steps.push({
                target: '#drawer-toggle',
                content: t('dashboardGuideHomeStep10'),
                disableBeacon: true
            });
        }

        return _steps;
    }, [t, showReportWidgets, isDesktop]);

    const navigate = useNavigate();

    const columns = useMemo(() => {
        return [
            {
                minSize: 240,
                header: 'Domain',
                accessorKey: 'domain',
                id: 'homeDomain',
                filterFn: 'contains',
                Cell: ({ cell }) => {
                    return (
                        <Box
                            sx={{
                                'cursor': 'pointer',
                                ':hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                            onClick={() => {
                                setCurrentAuditEntryId(cell.row.original.id);
                                navigate('/report/overview');
                            }}
                        >
                            {cell.row.original.domain}
                        </Box>
                    );
                }
            },
            {
                minSize: 130,
                size: 130,
                header: t('overallRating'),
                accessorKey: 'summaryDetailScore',
                filterFn: 'contains',
                Cell: ({ cell }) => {
                    return (
                        <Box display='flex' justifyContent='center'>
                            {/* <Box
                                width='74px'
                                py={0.5}
                                textAlign='center'
                                borderRadius={2}
                                backgroundColor='primary.main'
                                color={ProgressBarColors(cell.row.original.summaryDetailScore)}
                            >
                                {Number(cell.row.original.summaryDetailScore)?.toFixed(1) + ' %'}
                            </Box> */}
                            <CircularProgressWithLabel
                                color={ProgressBarColors(cell.row.original.summaryDetailScore)}
                                value={cell.row.original.summaryDetailScore}
                            />
                        </Box>
                    );
                }
            },
            {
                minSize: 90,
                size: 90,
                maxSize: 90,
                header: t('target'),
                accessorKey: 'conformanceTarget',
                filterVariant: 'multi-select',
                filterFn: (row, id, filterValue) => {
                    if (!filterValue?.length) return;
                    return filterValue.includes(row.original.conformanceTarget);
                },
                filterSelectOptions: [
                    { value: 'A', text: <LevelIcon level='A' /> },
                    { value: 'AA', text: <LevelIcon level='AA' /> },
                    { value: 'AAA', text: <LevelIcon level='AAA' /> },
                    { value: 'Best Practice', text: <LevelIcon level='Best Practice' /> }
                ],
                sortingFn: (rowA, rowB) => {
                    const a = rowA.original.conformanceTarget;
                    const b = rowB.original.conformanceTarget;
                    return a.localeCompare(b);
                },
                Cell: ({ cell }) => {
                    return (
                        <Box display='flex' gap={0.25} justifyContent='center'>
                            {cell.row.original.conformanceTarget.includes('AAA') ? (
                                <LevelIcon level='AAA' />
                            ) : cell.row.original.conformanceTarget.includes('AA') ? (
                                <LevelIcon level='AA' />
                            ) : (
                                <LevelIcon level='A' />
                            )}
                            {cell.row.original.conformanceTarget.includes('Best Practice') && (
                                <LevelIcon level='Best Practice' />
                            )}
                        </Box>
                    );
                }
            },
            {
                minSize: 100,
                size: 100,
                header: t('numberOfPages'),
                accessorKey: 'lastTotalPagesChecked',
                filterVariant: 'range'
            },
            {
                minSize: 90,
                size: 90,
                header: t('averageErrorCount'),
                accessorKey: 'lastAvgErrorCount',
                filterVariant: 'range'
            }
            /* {
                minSize: 150,
                header: 'Level A',
                accessorKey: 'auditScores.A',
                filterFn: 'contains',
                Cell: ({ cell }) => {
                    return (cell.row.original.auditScores.A * 100).toFixed(1);
                }
            },
            {
                minSize: 150,
                header: 'Level AA',
                accessorKey: 'auditScores.AA',
                filterFn: 'contains',
                Cell: ({ cell }) => {
                    return (cell.row.original.auditScores.AA * 100).toFixed(1);
                }
            },
            {
                minSize: 150,
                header: 'Level AAA',
                accessorKey: 'auditScores.AAA',
                filterFn: 'contains',
                Cell: ({ cell }) => {
                    return (cell.row.original.auditScores.AAA * 100).toFixed(1);
                }
            },
            {
                minSize: 150,
                header: 'Best Practice',
                accessorKey: 'auditScores.Best Practice',
                filterFn: 'contains',
                Cell: ({ cell }) => {
                    return (cell.row.original.auditScores['Best Practice'] * 100).toFixed(1);
                }
            } */
        ];
    }, [t, navigate, setCurrentAuditEntryId]);

    if (demoMode) {
        return <Navigate replace={false} to='/demo' />;
    }

    if (isLoading) return <LoadingSpinner />;

    return (
        <>
            <Grid xs={12}>
                {showReportWidgets && (
                    <Typography variant='h1' mt={1} mb={2}>
                        {t('homeGreeting')}
                    </Typography>
                )}
            </Grid>

            <Grid xs={12} id='homeAreaBox'>
                <ContentCard
                    icon={showReportWidgets ? IconReport : IconHome2}
                    title={showReportWidgets ? t('overviewOfAllDomains') : t('homeWelcome')}
                    noContentPadding={showReportWidgets}
                >
                    <Box alignItems='stretch' display='flex' flexWrap='wrap' gap={4} width='100%'>
                        {showReportWidgets ? (
                            <>
                                <ReactTable
                                    truncatedHeadCells
                                    truncatedHeadCellMaxWidth='92px'
                                    maxHeight='518px'
                                    data={Object.values(AllReportsData).map(val => val)}
                                    columns={columns}
                                    exportFilename={t('overviewOfAllDomains')}
                                    onlyDataKeysOnExport={[
                                        'domain',
                                        'summaryDetailScore',
                                        'conformanceTarget',
                                        'lastTotalPagesChecked',
                                        'lastAvgErrorCount'
                                    ]}
                                />
                            </>
                        ) : (
                            <>
                                <Box fontWeight={500} width='100%'>
                                    {t('dashboardText1')}
                                </Box>

                                <ProductCards />
                            </>
                        )}
                    </Box>
                </ContentCard>
            </Grid>

            <Grid xs={12}>
                <MainBlogArticles />
            </Grid>

            <UserTour steps={steps} />
        </>
    );
}
