import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import packageJson from '../package.json';

const version = packageJson.version;

/* import * as yupPkg from 'yup';
import yupTranslation from '@public/locales/de/yupTranslation'; */

/**
 * Builds the Yup Locale
 */
/* function buildYupLocale(_, t) {
  console.log('buildYupLocale');
  yupPkg.setLocale(yupTranslation);
  console.log(yup);
}

export const yup = yupPkg; */

i18next
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
        {
            fallbackLng: code => {
                // fixes the behavior that en & de are loaded when language is set to de, if we add more languages, we need to add
                // them here as well
                if (['de', 'en', 'es', 'it', 'es', 'fr', 'pt', 'pl', 'nl'].includes(code))
                    return false;
                return 'en'; // use en if detected lng is not available
            },
            //fallbackLng: false,
            debug: false,
            useSuspense: false,

            saveMissing: true,

            missingKeyHandler: (lngs, ns, key, fallbackValue) => {
                // Handle the missing key, for example, log it to the console
                if (key === 'homepage') return; // ignore this key, it is included but throws a warning

                console.warn(
                    `Missing translation for key: "${key}" in namespace: "${ns}" for languages: "${lngs.join(
                        ', '
                    )}". Fallback value: "${fallbackValue}"`
                );
            },

            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            },
            // react-i18next options
            react: {
                //wait: false,
                useSuspense: false,
                bindI18n: 'languageChanged'
            },
            backend: {
                loadPath: import.meta.env.DEV
                    ? `/locales/{{lng}}/{{ns}}.json`
                    : `/${version}/locales/{{lng}}/{{ns}}.json`
                /*request: async function (options, url, payload, callback) {
          //console.log('request', options, url);

          if (!url.startsWith('.')) url = `.${url}`;

          const res = await fetch(url);

          res.data = await res.text();

          callback(null, res);
        }*/
            },
            load: 'languageOnly' // only load main language code, not country specific

            // have a common namespace used around the full app
            // ns: ['translation'],
            // defaultNS: 'translation'
        }
        //buildYupLocale
    );

export default i18next;
