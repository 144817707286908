/**
 =========================================================
 * Material Home 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base breakpoints for the Material Home 2 PRO React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Material Home 2 PRO React using thie file.
 */

const breakpoints = {
    values: {
        xs: 0,
        sm: 556,
        md: 768,
        lg: 992,
        xl: 1320,
        xxl: 1500,
        xxxl: 1640
    }
};

export default breakpoints;
