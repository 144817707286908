import i18next from 'i18next';
import { printValue } from 'yup';

function getFieldName(path) {
    return i18next.exists(path) ? i18next.t(path) : 'Dit veld';
}

export default {
    mixed: {
        default: ({ path }) => `${getFieldName(path)} is ongeldig`,
        required: ({ path }) => `${getFieldName(path)} is een verplicht veld`,
        defined: ({ path }) => `${getFieldName(path)} moet gedefinieerd zijn`,
        notNull: ({ path }) => `${getFieldName(path)} mag niet null zijn`,
        oneOf: ({ path, values }) =>
            `${getFieldName(path)} moet een van de volgende waarden zijn: ${i18next.t(values)}`,
        notOneOf: ({ path, values }) =>
            `${getFieldName(path)} mag niet een van de volgende waarden zijn: ${i18next.t(values)}`,
        notType: ({ path, type, value, originalValue }) => {
            const isCast = originalValue != null && originalValue !== value;
            let msg =
                `${getFieldName(path)} moet van het type \`${i18next.t(type)}\` zijn, ` +
                `maar de uiteindelijke waarde was: \`${printValue(value, true)}\`` +
                (isCast ? ` (omgezet van de waarde \`${printValue(originalValue, true)}\`).` : '.');

            if (value === null) {
                msg +=
                    `\n Als "null" bedoeld is als een lege waarde, zorg dan dat het schema als` +
                    ' `.nullable()` gemarkeerd is';
            }

            return msg;
        }
    },
    string: {
        length: ({ path, length }) => `${getFieldName(path)} moet precies ${length} karakters zijn`,
        min: ({ path, min }) => `${getFieldName(path)} moet minstens ${min} karakters zijn`,
        max: ({ path, max }) => `${getFieldName(path)} mag maximaal ${max} karakters zijn`,
        matches: ({ path, regex }) => `${getFieldName(path)} moet overeenkomen met: "${regex}"`,
        email: ({ path }) => `${getFieldName(path)} moet een geldig e-mailadres zijn`,
        url: ({ path }) => `${getFieldName(path)} moet een geldige URL zijn`,
        uuid: ({ path }) => `${getFieldName(path)} moet een geldige UUID zijn`,
        trim: ({ path }) => `${getFieldName(path)} moet een bijgesneden string zijn`,
        lowercase: ({ path }) => `${getFieldName(path)} moet in kleine letters zijn`,
        uppercase: ({ path }) => `${getFieldName(path)} moet in hoofdletters zijn`
    },
    number: {
        min: ({ path, min }) => `${getFieldName(path)} moet groter dan of gelijk aan ${min} zijn`,
        max: ({ path, max }) => `${getFieldName(path)} moet kleiner dan of gelijk aan ${max} zijn`,
        lessThan: ({ path, less }) => `${getFieldName(path)} moet minder dan ${less} zijn`,
        moreThan: ({ path, more }) => `${getFieldName(path)} moet meer dan ${more} zijn`,
        positive: ({ path }) => `${getFieldName(path)} moet een positief getal zijn`,
        negative: ({ path }) => `${getFieldName(path)} moet een negatief getal zijn`,
        integer: ({ path }) => `${getFieldName(path)} moet een geheel getal zijn`
    },
    date: {
        min: ({ path, min }) => `${getFieldName(path)} veld moet later zijn dan ${min}`,
        max: ({ path, max }) => `${getFieldName(path)} veld moet eerder zijn dan ${max}`
    },
    object: {
        noUnknown: ({ path, unknown }) =>
            `${getFieldName(path)} veld bevat ongespecificeerde sleutels: ${i18next.t(unknown)}`
    },
    array: {
        min: ({ path, min }) => `${getFieldName(path)} veld moet ten minste ${min} items bevatten`,
        max: ({ path, max }) =>
            `${getFieldName(path)} veld mag niet meer dan ${max} items bevatten`,
        length: ({ path, length }) => `${getFieldName(path)} moet ${length} items bevatten`
    },
    boolean: {
        isValue: ({ path, value }) => `${getFieldName(path)} veld moet ${i18next.t(value)} zijn`
    }
};
