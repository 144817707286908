import ContentCard from './ContentCard';

export default function BoxWithTitle({ children, title, icon, sx, ...cardAndTitleProps }) {
    return (
        <ContentCard
            title={title}
            small
            icon={icon}
            sx={{
                'boxShadow': 'none',
                'border': '2px solid #000',
                'bgcolor': '#f0f0f0',
                '& .MuiInputBase-root, & .MuiButton-outlinedPrimary, .MuiFormControl-root': {
                    backgroundColor: '#fff'
                },
                ...sx
            }}
            {...cardAndTitleProps}
        >
            {children ? children : null}
        </ContentCard>
    );
}
