import { assistLicensesAtom, auditLicensesAtom, demoModeAtom } from '@/global-store';
import useFormSchema from '@/hooks/useFormSchema';
import DynamicDialog from '@components/DynamicDialog.jsx';
import { usePostSupportRequest } from '@http/queries';
import { Box, Checkbox, ListItemText, MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { IconMessageCircleQuestion, IconSend } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { Select, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export default function SupportPopupButton({ color = 'secondary' }) {
    const [demoMode] = useAtom(demoModeAtom);
    const [assistLicenses] = useAtom(assistLicensesAtom);
    const [auditLicenses] = useAtom(auditLicensesAtom);

    const { t } = useTranslation();
    const { validate, required } = useFormSchema(
        yup.object({
            domain: yup.string().max(500),
            category: yup.string().max(500),
            message: yup.string().max(5000).required(),
            selectedDomains: yup.array().of(yup.string().max(500)).nullable()
        })
    );

    const postSupportRequest = usePostSupportRequest();

    const categoryOptions = [
        'Eye-Able Assist',
        'Eye-Able Audit',
        'Eye-Able Report',
        t('supportOthers')
    ];

    const assistDomains = assistLicenses.map(item => item.domain);
    const auditDomains = auditLicenses.map(item => item.domains);

    const websites = {
        'Eye-Able Assist': assistDomains,
        'Eye-Able Audit': auditDomains
    };

    //send support request to api
    async function handleSendRequest(values, form) {
        const { message, selectedDomains, category } = values;

        let supportMsg;

        if (demoMode) {
            supportMsg = `Category: Demo Contact Form | Message: ${message}`;
        } else {
            supportMsg = `Category: ${category} | Message: ${message}`;

            if (selectedDomains?.length) {
                supportMsg += ` | Domains: ${selectedDomains.join(' & ')}`;
            }
        }

        try {
            const response = await postSupportRequest.mutateAsync(supportMsg);
            console.log('support response:', response);
            form.restart();
            return true; // close the modal
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const closeAction = form => {
        form.restart();
        return true;
    };

    return (
        <Form
            initialValues={{
                category: t('supportOthers'),
                selectedDomains: [],
                message: t('helloTeam')
            }}
            onSubmit={handleSendRequest}
            render={({ handleSubmit, form, values }) => (
                <form onSubmit={handleSubmit}>
                    <DynamicDialog
                        activatorProps={{
                            color: color,
                            startIcon: <IconMessageCircleQuestion />
                        }}
                        activatorText={t('websiteSupportButton')}
                        onCancel={() => closeAction(form)}
                        onSubmit={handleSubmit}
                        submitIcon={<IconSend />}
                        submitText={t('submit')}
                        title={t('supportTitle')}
                    >
                        <Box my={1.5}>
                            {demoMode ||
                            (!auditDomains?.length && !assistDomains?.length) ? null : (
                                <Grid
                                    container
                                    flexWrap
                                    justifyContent='space-between'
                                    mb={2}
                                    spacing={3}
                                >
                                    <Grid display='flex' flexDirection='column' md={6} xs={12}>
                                        <Select
                                            label={t('supportSelectCategory')}
                                            name='category'
                                            onChange={e => {
                                                form.change('selectedDomains', []);
                                                form.change('category', e.target.value);
                                            }}
                                            required={required.category}
                                            variant='outlined'
                                        >
                                            {categoryOptions.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    {values.category === 'Eye-Able Report' ||
                                    values.category === t('supportOthers') ? null : (
                                        <Grid display='flex' flexDirection='column' md={6} xs={12}>
                                            <Select
                                                label={t('supportSelectWebsite')}
                                                multiple
                                                name='selectedDomains'
                                                renderValue={selected => selected.join(', ')}
                                                required={required.selectedDomains}
                                                variant='outlined'
                                            >
                                                {websites[values.category]?.map((site, index) => (
                                                    <MenuItem key={site + index} value={site}>
                                                        <Checkbox
                                                            checked={
                                                                values.selectedDomains.indexOf(
                                                                    site
                                                                ) > -1
                                                            }
                                                        />

                                                        <ListItemText primary={site} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    )}
                                </Grid>
                            )}

                            <Grid container direction='column'>
                                <TextField
                                    fullWidth
                                    label={t('message')}
                                    multiline
                                    name='message'
                                    required={required.message}
                                    rows={4}
                                    variant='outlined'
                                />
                            </Grid>
                        </Box>
                    </DynamicDialog>
                </form>
            )}
            validate={validate}
        />
    );
}
