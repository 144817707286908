export default function AllyIcon(props) {
    return (
        <svg
            className='custom-svg-icon'
            id='ally-icon'
            data-name='Ebene 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            {...props}
        >
            <defs>
                <style>
                    {`
                        #ally-icon .cls-1 {
                            stroke: currentColor;
                            stroke-miterlimit: 10;
                        }
                        #ally-icon .cls-1, #ally-icon .cls-2 {
                            fill: none;
                            stroke: currentColor;
                            stroke-width: 1.55px;
                        }
                        #ally-icon .cls-2 {
                            stroke: currentColor;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                        }
                    `}
                </style>
            </defs>
            <path
                className='cls-1'
                d='M18,4c1.66,0,3,1.34,3,3v8c0,1.66-1.34,3-3,3h-5l-5,3v-3h-2c-1.66,0-3-1.34-3-3V7c0-1.66,1.34-3,3-3h12Z'
            />
            <g>
                <path
                    className='cls-2'
                    d='M13.38,17.47v-4.15l2.49-.02c.75,0,1.46-.35,1.92-.95h0c.32-.42.49-.93.49-1.46v-1.68h-3.03l-2.43-2.46h-2.68c-2.13,0-3.86,1.73-3.86,3.86v2.14c0,1.02.83,1.85,1.85,1.85h0c1.02,0,1.85-.83,1.85-1.85v-2.46'
                />
                <path className='cls-2' d='M6.4,14.1c-.69.89-1.38,1.78-2.06,2.67' />
            </g>
        </svg>
    );
}
