import { toastsAtom } from '@/global-store';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export function useNotification() {
    const { t } = useTranslation();
    const [toasts, setToasts] = useAtom(toastsAtom);

    const show = (message, severity, duration) => {
        setToasts([
            ...toasts,
            {
                message: message || t('errorOccurred'),
                severity,
                key: new Date().getTime(),
                duration
            }
        ]);
    };

    return {
        info(message, duration) {
            show(message, 'info', duration);
        },
        success(message, duration) {
            show(message, 'success', duration);
        },
        warning(message, duration) {
            show(message, 'warning', duration);
        },
        error(message, duration) {
            show(message, 'error', duration);
        }
    };
}
