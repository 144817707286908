import StringAvatar from '@components/StringAvatar';
import { avatarColors } from '@helpers/constants';
import { Avatar, Box } from '@mui/material';
import { IconUser } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export default function UserAvatar({
    user,
    small = false,
    me = false,
    hideName = false,
    titleProps = {},
    label = null,
    sx = {}
}) {
    const { t } = useTranslation();

    const fullName = `${user?.surname + ' ' || ''}${user?.name || ''}`;

    const avatarSize = 28;
    const smallSizeProps = small
        ? { width: avatarSize, height: avatarSize, fontSize: '12px !important' }
        : { fontSize: '15px !important' };

    const userAvatar = user ? (
        <StringAvatar
            text={fullName}
            small={small}
            avatarSize={avatarSize}
            bgColor={me ? avatarColors.me : avatarColors[user.orgAdmin]}
        />
    ) : (
        <Avatar sx={{ ...smallSizeProps }}>
            <IconUser />
        </Avatar>
    );

    return hideName ? (
        userAvatar
    ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, ...sx }}>
            {userAvatar}

            <Box
                sx={{
                    fontWeight: me ? 600 : 400,
                    ...titleProps
                }}
            >
                {user ? label ?? fullName : t('unassigned')}
            </Box>
        </Box>
    );
}
