import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function showErrorOnChange({
    meta: { submitError, dirtySinceLastSubmit, error, touched, modified }
}) {
    return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));
}

function PhoneWrapper(props) {
    const { t, i18n } = useTranslation();
    const {
        input: { name, value, onChange, onBlur, onFocus, ...restInput },
        meta,
        required,
        fullWidth = true,
        helperText,
        showError = showErrorOnChange,
        ...rest
    } = props;

    const { error, submitError } = meta;
    const isError = showError({ meta });

    return (
        <MuiTelInput
            autoComplete='off'
            defaultCountry='DE'
            error={isError}
            fullWidth={fullWidth}
            helperText={isError ? error || submitError : helperText}
            inputProps={{ required, ...restInput }}
            label={t('phone')}
            langOfCountryName={i18n.detectedLng || 'en'}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            preferredCountries={['DE', 'AT', 'CH', 'IT', 'FR', 'ES', 'NL', 'BE', 'GB', 'US']}
            required={required}
            value={value}
            {...rest}
        />
    );
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
function PhoneInput({ name, fieldProps, required, ...rest }) {
    const { t } = useTranslation();

    return (
        <Field
            name={name}
            render={({ input, meta }) => (
                <PhoneWrapper input={input} meta={meta} name={name} required={required} {...rest} />
            )}
            validate={value => {
                if (required) {
                    if (!value || !matchIsValidTel(value)) {
                        return t('validPhone');
                    }
                } else {
                    if (value && !matchIsValidTel(value)) {
                        return t('validPhone');
                    }
                }

                return undefined;
            }}
            {...fieldProps}
        />
    );
}

PhoneInput.propTypes = {
    name: PropTypes.string
};

export default PhoneInput;
