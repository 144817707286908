import { availableAuditEntriesAtom } from '@/global-store';
import { Autocomplete, Box, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

/**
 * DomainSelect component
 * @param onChange - custom change handler, will disable reloading and does not save the domain to the global store
 * @param sx - sx style object
 * @returns {JSX.Element}
 * @constructor
 */
function DomainSelect({
    initialEntryId,
    onChange,
    width = '100%',
    size = 'standard',
    borderRadius = 1,
    sx = {}
}) {
    const [availableAuditEntries] = useAtom(availableAuditEntriesAtom);

    const [tempCurrentAuditEntryId, setTempCurrentAuditEntryId] = useState(initialEntryId);

    const domains = useMemo(() => {
        if (!availableAuditEntries) return [];
        const _domains = [];

        const countMap = {};

        for (const entry of availableAuditEntries) {
            let label = entry.domain;

            if (label.startsWith('http://') || label.startsWith('https://')) {
                label = label.replace(/^(http|https):\/\//, '');
            }

            if (label.endsWith('/')) {
                label = label.slice(0, -1);
            }

            if (countMap[label]) {
                countMap[label]++;
                label = `${label} (${countMap[label]})`;
            } else {
                countMap[label] = 1;
            }

            _domains.push({
                ...entry,
                _label: label
            });
        }

        return _domains;
    }, [availableAuditEntries]);

    const currentEntry = useMemo(
        () => domains.find(domain => domain.id === tempCurrentAuditEntryId) || null,
        [domains, tempCurrentAuditEntryId]
    );

    useEffect(() => {
        // if the onChange prop is set, we notify the parent component about the change
        domains &&
            tempCurrentAuditEntryId &&
            onChange?.(domains.find(domain => domain.id === tempCurrentAuditEntryId));
    }, [tempCurrentAuditEntryId, domains, onChange]);

    if (!domains?.length) return <></>;

    return (
        <Grid
            container
            gap={1.25}
            alignItems='center'
            flexWrap='nowrap'
            sx={{
                width: { xs: '100%', sm: width },
                minWidth: 180,
                ...sx
            }}
        >
            {domains?.length < 2 ? (
                <Box
                    fontWeight={500}
                    padding='7.5px 12px'
                    border='1.75px solid rgba(18, 18, 18, 0.5)'
                    borderRadius={borderRadius}
                >
                    Domain: <b>{currentEntry?._label || ' - '}</b>
                </Box>
            ) : (
                <Autocomplete
                    fullWidth
                    renderInput={params => (
                        <TextField
                            {...params}
                            size={size}
                            variant='outlined'
                            fullWidth
                            label='Domain'
                            sx={{
                                backgroundColor: 'rgba(255,255,255,0.33)',
                                borderRadius: borderRadius
                            }}
                        />
                    )}
                    sx={{
                        '& fieldset': {
                            borderRadius: borderRadius
                        }
                    }}
                    disableClearable
                    selectOnFocus
                    blurOnSelect
                    autoSelect
                    autoComplete
                    autoHighlight
                    openOnFocus
                    value={currentEntry}
                    onChange={(event, newValue) => setTempCurrentAuditEntryId(newValue?.id)}
                    options={domains}
                    getOptionLabel={option => option?._label}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                />
            )}
        </Grid>
    );
}

export default DomainSelect;
