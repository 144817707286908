import LoadingSpinner from '@components/LoadingSpinner';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType
} from 'react-router-dom';
import routes from './routes';

const isDev = !import.meta.env.PROD;

let environment = 'development';

if (!isDev) {
    environment = window.location.host === 'dashboard.eye-able.com' ? 'production' : 'staging';
}

console.log('Environment:', environment);

Sentry.init({
    dsn: 'https://de07898459224d19a10ceda0d370b34b@o4504741085249536.ingest.sentry.io/4504741227003904',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: isDev ? 0 : environment === 'staging' ? 0.5 : 0.1,
    //   This sets the sample rate to be 20% on production and to 100% on staging
    replaysSessionSampleRate: 0.05, // Capture Replay for 10% of all sessions
    replaysOnErrorSampleRate: 1.0, // plus for 100% of sessions with an error
    enabled: import.meta.env.PROD, // enable sentry only in production & staging mode
    environment: environment
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

function Router() {
    return <RouterProvider fallbackElement={<LoadingSpinner />} router={router} />;
}

export default Router;
