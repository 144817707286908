import { userAtom } from '@/global-store.jsx';
import useFormSchema from '@/hooks/useFormSchema';
import PhoneInput from '@components/PhoneInput.jsx';
import { useNotification } from '@hooks/useNotification.jsx';
import {
    useCreateUser,
    useFetchOrganisations,
    usePostOrganisationAdmin,
    useUpdateUser
} from '@http/queries.js';
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { IconEdit, IconUserPlus } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { Autocomplete, Select, Switches, TextField } from 'mui-rff';
import nanoclone from 'nanoclone';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import DynamicDialog from './DynamicDialog';

export default function UserDialog({
    userData,
    onSuccess,
    hideOrgAdminToggle = false,
    showOrgSelect = false,
    ...dialogProps
}) {
    const { t, i18n } = useTranslation();
    const notification = useNotification();
    const [currentUser] = useAtom(userAtom);

    const createUserMutation = useCreateUser();
    const editUserMutation = useUpdateUser();

    const createOrg = usePostOrganisationAdmin();

    let { data: orgList, isLoading } = useFetchOrganisations();

    const languages = [
        {
            label: t('de'),
            value: 'de'
        },
        {
            label: t('en'),
            value: 'en'
        }
    ];

    let editUser = !!userData;

    const defaultValues = {
        role: 'customer',
        mailLang: i18n.resolvedLanguage === 'de' ? 'de' : 'en',
        sendSetupMail: !editUser,
        primaryOrganisation: showOrgSelect ? null : currentUser.primaryOrganisation,
        orgAdmin: userData?.orgAdmin || currentUser.isAdmin || currentUser.salesAdmin,
        firstName: '',
        lastName: '',
        company: showOrgSelect ? null : currentUser.company,
        email: '',
        phone: ''
    };

    if (!userData) {
        userData = defaultValues;
    }

    const initialValues = {
        ...defaultValues,
        ...userData
    };

    const { validate, required } = useFormSchema(
        yup.object({
            firstName: yup.string().max(128).required(),
            lastName: yup.string().max(128).required(),
            email: yup.string().max(128).email().required(),
            mailLang: yup.string().required().default('de'),
            sendSetupMail: yup.boolean().default(false),
            role: yup
                .string()
                .oneOf(['customer', 'partner', 'admin', 'publicAdmin', 'salesAdmin'])
                .default('customer'),
            primaryOrganisation:
                userData.primaryOrganisation || !editUser
                    ? yup.number().min(1).required()
                    : yup.number().min(1).nullable(),
            orgAdmin: yup
                .boolean()
                .default(userData.orgAdmin || currentUser.isAdmin || currentUser.salesAdmin)
        })
    );

    async function createUser(body, form) {
        try {
            const res = await createUserMutation.mutateAsync(body);

            const message = t('userCreatedSuccess');
            notification.success(message, 15000);

            form.restart();

            onSuccess?.(res);
            return true;
        } catch (error) {
            let message = t('userCreatedFailed');

            if (error?.message?.includes('Email already')) {
                message = t('mailAlreadyExists');
            }

            notification.error(message, 15000);
            console.error(message + '\n\n' + JSON.stringify(error, null, 2));
        }
    }

    async function updateUser(body, form) {
        try {
            const res = await editUserMutation.mutateAsync({
                modifyUserApiKey: userData.apiKey,
                ...body
            });

            const message = t('userUpdatedSuccess');
            notification.success(message, 15000);

            form.restart();

            onSuccess?.(res);
            return true;
        } catch (error) {
            const message = t('userUpdatedFailed');

            notification.error(message, 15000);
            console.error(message + '\n\n' + JSON.stringify(error, null, 2));
        }
    }

    function submit(values, form) {
        console.log('submit', values);
        const body = { ...userData, ...values };

        body.company = body.primaryOrganisationObject?.name || null;
        delete body.fullName;

        if (editUser) {
            return updateUser(body, form);
        } else {
            return createUser(body, form);
        }
    }

    return (
        <Form
            validate={validate}
            key={`${editUser}-${isLoading}`}
            initialValues={initialValues}
            onSubmit={submit}
            render={({ handleSubmit, form }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <DynamicDialog
                        onSubmit={handleSubmit}
                        title={editUser ? t('editUser') : t('addUser')}
                        activatorText={editUser ? t('editUser') : t('addUser')}
                        activatorProps={{
                            color: 'primary',
                            variant: 'contained',
                            startIcon: editUser ? <IconEdit /> : <IconUserPlus />
                        }}
                        {...dialogProps}
                    >
                        <Grid
                            alignItems='center'
                            container
                            gap={2.5}
                            justifyContent='center'
                            my={2.5}
                            px={{ xs: 3, md: 4, xl: 5.5 }}
                        >
                            <TextField
                                autoFocus
                                autoComplete='new-password'
                                fullWidth
                                label={t('firstName')}
                                name='firstName'
                                required={required.firstName}
                            />

                            <TextField
                                autoComplete='new-password'
                                fullWidth
                                label={t('lastName')}
                                name='lastName'
                                required={required.lastName}
                            />

                            <TextField
                                autoComplete='new-password'
                                fullWidth
                                label='Email'
                                name='email'
                                required={required.email}
                            />

                            <PhoneInput name='phone' />

                            {/* <CountrySelect /> */}

                            {showOrgSelect && (
                                <Autocomplete
                                    autoHighlight
                                    disableClearable
                                    fullWidth
                                    label={t('organisation')}
                                    name='primaryOrganisation'
                                    required={required.primaryOrganisation}
                                    getOptionValue={option => option?.id}
                                    getOptionLabel={option => option?.name || ''}
                                    openOnFocus
                                    isOptionEqualToValue={(option, value) =>
                                        typeof value === 'object'
                                            ? option.id === value.id
                                            : option.id === value
                                    }
                                    noOptionsText={t('noOptions')}
                                    options={orgList ?? []}
                                    onChange={async (event, newValue) => {
                                        if (newValue?.inputValue) {
                                            // copy the values so we can fill them back in after the new org is created
                                            const previousValues = nanoclone(
                                                form.getState().values
                                            );

                                            // Create a new organisation
                                            const newOrg = await createOrg.mutateAsync({
                                                name: newValue.inputValue
                                            });

                                            console.log('newOrg', newOrg);

                                            form.reset();

                                            setTimeout(() => {
                                                form.batch(() => {
                                                    form.change(
                                                        'firstName',
                                                        previousValues.firstName
                                                    );
                                                    form.change(
                                                        'lastName',
                                                        previousValues.lastName
                                                    );
                                                    form.change('email', previousValues.email);
                                                    form.change('phone', previousValues.phone);
                                                    form.change('role', previousValues.role);
                                                    form.change(
                                                        'mailLang',
                                                        previousValues.mailLang
                                                    );
                                                    form.change('primaryOrganisation', newOrg.id);
                                                });
                                            }, 350);
                                        } else {
                                            form.change('primaryOrganisation', newValue?.id);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        options = options || [];

                                        const { inputValue } = params;

                                        const _inputValue = params.inputValue.trim().toLowerCase();

                                        if (_inputValue === '') return options;

                                        const filtered = options.filter(option =>
                                            option.name?.toLowerCase()?.includes(_inputValue)
                                        );

                                        const match = options.find(
                                            option => option.name.toLowerCase() === _inputValue
                                        );

                                        if (!match) {
                                            filtered.push({
                                                id: 'add',
                                                inputValue,
                                                name: `Create a new organisation called "${inputValue}"`
                                            });
                                        }

                                        return filtered;
                                    }}
                                />
                            )}

                            <Select
                                formControlProps={{ fullWidth: true }}
                                fullWidth
                                label={t('mailLang')}
                                name='mailLang'
                            >
                                {languages.map(lang => (
                                    <MenuItem key={lang.value} value={lang.value}>
                                        {lang.label}
                                    </MenuItem>
                                ))}
                            </Select>

                            {currentUser.isAdmin && userData.id !== currentUser.id && (
                                <TextField
                                    autoComplete='new-password'
                                    fullWidth
                                    label={t('role')}
                                    name='role'
                                    required={required.role}
                                    select
                                >
                                    <MenuItem value='customer'>Customer</MenuItem>

                                    <MenuItem value='publicAdmin'>PublicAdmin</MenuItem>

                                    <MenuItem value='partner'>Partner</MenuItem>

                                    <MenuItem value='salesAdmin'>SalesAdmin</MenuItem>

                                    <MenuItem value='admin'>ADMIN</MenuItem>
                                </TextField>
                            )}

                            {!editUser && (currentUser.isAdmin || currentUser.isSalesAdmin) && (
                                <>
                                    <Switches
                                        name='sendSetupMail'
                                        data={{ label: 'Send mail to user', value: false }}
                                    />
                                </>
                            )}

                            {!hideOrgAdminToggle &&
                                (currentUser.isAdmin || currentUser.isOrgAdmin) && (
                                    <Switches
                                        name='orgAdmin'
                                        data={{
                                            label: `${t('orgAdmin')}?`,
                                            value:
                                                userData.orgAdmin ||
                                                currentUser.isAdmin ||
                                                currentUser.salesAdmin
                                        }}
                                    />
                                )}
                        </Grid>
                    </DynamicDialog>
                </form>
            )}
        />
    );
}
