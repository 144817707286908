// Material Home 2 React Helper Functions
import pxToRem from '@assets/theme/functions/pxToRem';

const borders = {
    borderColor: '#C4C4C4',

    borderWidth: {
        0: 0,
        1: pxToRem(1),
        2: pxToRem(2),
        3: pxToRem(3),
        4: pxToRem(4),
        5: pxToRem(5),
        6: pxToRem(6),
        7: pxToRem(7)
    },

    borderRadius: {
        xs: pxToRem(1.6),
        sm: pxToRem(2),
        md: pxToRem(6),
        lg: pxToRem(8),
        xl: pxToRem(12),
        xxl: pxToRem(16),
        section: pxToRem(160)
    }
};

export default borders;
